import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';
export const ConfirmationBox = ({ message, buttonLink, buttonText, icon }) => {
  return (
    <Modal isOpen centered style={{ width: '277px', borderRadius: '6px' }} className="confirmation-modal">
      <div className="confirmation-box">
        <div className="confirmation-icon-box">{icon}</div>
        <div className="confirmation-message-box">
          <span className="confirmation-message">{message}</span>
          <Link data-testid="confirmation-button" to={buttonLink} className="btn btn-primary btn-small">
            {buttonText}
          </Link>
        </div>
      </div>
    </Modal>
  );
};
