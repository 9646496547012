import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Card, Label, InputGroup, FormFeedback } from 'reactstrap';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity, reset } from './metric.reducer';
import { Controller, FieldError, useForm } from 'react-hook-form';
import Select from 'react-select';
import { reactStrapStyles } from 'app/shared/util/form-utils';
import _ from 'lodash';
import MetricRoutes from '.';

export const MetricUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const metricEntity = useAppSelector(state => state.metric.entity);
  const loading = useAppSelector(state => state.metric.loading);
  const updating = useAppSelector(state => state.metric.updating);
  const updateSuccess = useAppSelector(state => state.metric.updateSuccess);

  const handleClose = () => {
    navigate('/metric' + location.search);
  };
  const typeOptions = [
    { label: 'Primitive', value: 'Primitive' },
    { label: 'Computed', value: 'Computed' },
  ];
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset: resetForm,
  } = useForm();

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...metricEntity,
      ...values,
      type: values?.type?.value,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = useMemo(
    () =>
      isNew
        ? {}
        : {
            ...metricEntity,
            type: typeOptions?.find(t => t.value === metricEntity?.type),
          },
    [metricEntity],
  );
  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [resetForm, defaultValues]);

  return (
    <Card className="jh-card">
      <Row className="justify-content-start">
        <Col md="12">
          <h2 id="rainspotApp.metric.home.createOrEditLabel" data-cy="metricCreateUpdateHeading">
            <Translate contentKey={isNew ? `rainspotApp.metric.home.createLabel` : `rainspotApp.metric.home.editLabel`}>
              Create or edit a Metric
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="mt-10">&nbsp;</div>
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
              <div className="form-body">
                <div className="d-flex">
                  <ValidatedField
                    register={register}
                    type="text"
                    id="metric-name"
                    data-cy="name"
                    name="name"
                    error={errors?.name as FieldError}
                    label={translate('rainspotApp.metric.name') + '*'}
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                </div>
                <div className="d-flex">
                  <ValidatedField
                    register={register}
                    type="text"
                    id="metric-description"
                    data-cy="description"
                    name="description"
                    error={errors?.name as FieldError}
                    label={translate('rainspotApp.metric.description') + '*'}
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                </div>
                <div style={{ display: 'flex' }} className="flex-column">
                  <Label for="metricType">{'Metric Type'}*</Label>
                  <InputGroup className="mb-3" error={errors?.countyId} data-testid="county">
                    <Controller
                      control={control}
                      name="type"
                      rules={{ required: translate('entity.validation.required') }}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <Select
                              classNamePrefix=""
                              menuPlacement="auto"
                              options={typeOptions || []}
                              styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                              value={field.value}
                              onChange={(e: any) => {
                                field.onChange(e);
                              }}
                            />

                            <FormFeedback>{fieldState.error?.message}</FormFeedback>
                          </>
                        );
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
              <hr />
              <div className="justify-content-end d-flex form-footer">
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/neighborhood" replace color="info">
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.cancel"></Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default MetricUpdate;
