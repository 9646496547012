import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ComputedMetric from './computed-metric';
import ComputedMetricDetail from './computed-metric-detail';
import ComputedMetricUpdate from './computed-metric-update';
import ComputedMetricDeleteDialog from './computed-metric-delete-dialog';

const ComputedMetricRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ComputedMetric />} />
    <Route path="new" element={<ComputedMetricUpdate />} />
    <Route path=":id">
      <Route index element={<ComputedMetricDetail />} />
      <Route path="edit" element={<ComputedMetricUpdate />} />
      <Route path="delete" element={<ComputedMetricDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ComputedMetricRoutes;
