import React from 'react';

function DeviceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.33329 2.66667H3.99996C3.26358 2.66667 2.66663 3.26362 2.66663 4V9.33333C2.66663 10.0697 3.26358 10.6667 3.99996 10.6667H12C12.7363 10.6667 13.3333 10.0697 13.3333 9.33333V8M12 2V4M12 4V6M12 4H9.99996M12 4H14M7.99996 10.6667V13.3333M5.33329 13.3333H10.6666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeviceIcon;
