import dayjs from 'dayjs';
import { IDevice } from 'app/shared/model/device.model';
import { PayloadDirection } from 'app/shared/model/enumerations/payload-direction.model';

export interface IDevicePayload {
  id?: number;
  attribute?: string;
  direction?: keyof typeof PayloadDirection;
  value?: string;
  timeOfOrigin?: dayjs.Dayjs;
  timeOfIngestion?: dayjs.Dayjs;
  device?: IDevice | null;
}

export const defaultValue: Readonly<IDevicePayload> = {};
