import React from 'react';

function CheckCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
      <g id="check-circle" data-testid="check-circle">
        <path
          id="Vector"
          d="M13.4167 24.9167L19.1667 30.6667L32.5833 17.25M40.25 23C40.25 32.5269 32.5269 40.25 23 40.25C13.4731 40.25 5.75 32.5269 5.75 23C5.75 13.4731 13.4731 5.75 23 5.75C32.5269 5.75 40.25 13.4731 40.25 23Z"
          stroke="#FAFAFB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default CheckCircle;
