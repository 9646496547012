import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DeviceReportedMetric from './device-reported-metric';
import DeviceReportedMetricDetail from './device-reported-metric-detail';
import DeviceReportedMetricUpdate from './device-reported-metric-update';
import DeviceReportedMetricDeleteDialog from './device-reported-metric-delete-dialog';

const DeviceReportedMetricRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DeviceReportedMetric />} />
    <Route path="new" element={<DeviceReportedMetricUpdate />} />
    <Route path=":id">
      <Route index element={<DeviceReportedMetricDetail />} />
      <Route path="edit" element={<DeviceReportedMetricUpdate />} />
      <Route path="delete" element={<DeviceReportedMetricDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DeviceReportedMetricRoutes;
