import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BrandIcon = (props: { imgH?: string; imgW?: string }) => (
  <div className="brand-icon">
    <img height={props.imgH} src="content/images/rainspot-htag.svg" alt="Logo" />
  </div>
);

export const BrandIconLarge = (props: { imgH?: string; imgW?: string }) => (
  <div className="brand-icon">
    <img height={props.imgH} src="content/images/rainspot-vtag.svg" alt="Logo" />
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon imgH="35px" />
  </NavbarBrand>
);

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);
