import UploadIcon from 'app/shared/icons/upload-icon';
import { downloadFile } from 'app/shared/util/file-utils';
import React, { useRef } from 'react';
import { FormFeedback, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

type FileInputProps = {
  onChange?: any;
  name: string;
  label?: string;
  dataCy?: string;
  placeholder?: string;
  accept?: string;
  s3url?: string;
  formControl?: any;
  isRequired?: boolean;
};
export const FileInput = ({
  onChange: onChange,
  label,
  name,
  dataCy,
  placeholder,
  accept,
  formControl,
  s3url,
  isRequired,
}: FileInputProps) => {
  const {
    watch,
    formState: { errors },
    register,
    touchedFields,
  } = formControl;
  const watchFile = watch(name);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { ref, ...fields } = register(name, {
    validate(va) {
      if (isRequired && va?.length === 0) {
        return 'File is required';
      }
    },
    onChange(field) {
      onChange?.(field);
    },
  });

  return (
    <>
      <Label>{label || 'Upload File'}</Label>
      <InputGroup className="mb-3" error={errors?.[name]}>
        <Input
          id={name}
          width={'200px'}
          data-cy={dataCy}
          data-testid={dataCy}
          type={'text'}
          style={{ cursor: 'pointer' }}
          readOnly
          onClick={() => {
            inputRef.current?.click();
          }}
          value={watchFile?.[0]?.name || ''}
          placeholder={placeholder}
          aria-label="file"
          aria-describedby="file-input"
          aria-invalid={errors?.[name] ? 'true' : 'false'}
          className={`form-control file-input ${touchedFields?.[name] ? 'is-touched' : ''} ${errors?.[name] ? 'is-invalid' : ''} ${
            touchedFields?.[name] && !errors?.[name] ? 'is-touched is-valid' : ''
          }`}
          required
        />
        <InputGroupText
          data-testid="file-upload"
          className={`${errors?.[name] ? 'is-invalid' : ''} ${touchedFields?.[name] && !errors?.[name] ? 'is-touched is-valid' : ''}`}
        >
          {
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                inputRef?.current?.click();
              }}
            >
              <UploadIcon />
            </span>
          }
          <input
            multiple={false}
            hidden
            type="file"
            accept={accept}
            {...fields}
            ref={el => {
              ref(el);
              inputRef.current = el;
            }}
            data-testid={'file-input'}
          />
        </InputGroupText>
        {errors?.[name] && <FormFeedback>{errors?.[name]?.message as string}</FormFeedback>}
        {!!s3url && (
          <span
            onClick={() => {
              downloadFile(s3url);
            }}
            className="downloadLink primary-link"
          >
            {'Firmware'}
          </span>
        )}
      </InputGroup>
    </>
  );
};
