import EyeHide from 'app/shared/icons/eye-hide';
import EyeShow from 'app/shared/icons/eye-show';
import React, { useState } from 'react';
import { FormFeedback, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { translate } from 'react-jhipster';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { registerRs } from 'app/shared/util/form-utils';

type PasswordFieldInputs = {
  errors?: FieldErrors<FieldValues>;
  touchedFields?: any;
  register: any;
  onChange?: (val) => void;
  name: string;
  label?: string;
  validate?: any;
  dataCy?: string;
  placeholder?: string;
};
export const PasswordInput = ({
  errors,
  touchedFields,
  register,
  onChange: onPasswordChange,
  label,
  name,
  validate,
  dataCy,
  placeholder,
}: PasswordFieldInputs) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {' '}
      <Label>{label}*</Label>
      <InputGroup className="mb-3" error={errors?.[name]}>
        <Input
          data-cy={dataCy}
          data-testid={dataCy}
          name={name}
          type={showPassword ? 'text' : 'password'}
          label={label || translate('login.form.password')}
          placeholder={placeholder}
          aria-label="password"
          aria-describedby="password-input"
          {...registerRs(
            name,
            {
              ...validate,
              onChange(e) {
                onPasswordChange?.(e.target.value);
              },
            },
            register,
          )}
          aria-invalid={errors?.[name] ? 'true' : 'false'}
          className={`form-control password-input ${touchedFields?.[name] ? 'is-touched' : ''} ${errors?.[name] ? 'is-invalid' : ''} ${
            touchedFields?.[name] && !errors?.[name] ? 'is-touched is-valid' : ''
          }`}
          required
        />
        <InputGroupText
          data-testid="password-toggle"
          className={`${errors?.[name] ? 'is-invalid' : ''} ${touchedFields?.[name] && !errors?.[name] ? 'is-touched is-valid' : ''}`}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeShow /> : <EyeHide />}
        </InputGroupText>
        {errors?.[name] && <FormFeedback>{errors?.[name]?.message as string}</FormFeedback>}
      </InputGroup>
    </>
  );
};
