import dayjs from 'dayjs';
import { IDeviceReportedMetric } from 'app/shared/model/device-reported-metric.model';
import { IDevicePayload } from 'app/shared/model/device-payload.model';
import { IApplicationUser } from 'app/shared/model/application-user.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { IComment } from 'app/shared/model/comment.model';
import { ILocation } from 'app/shared/model/location.model';
import { IMesh } from 'app/shared/model/mesh.model';
import { ICity } from 'app/shared/model/city.model';
import { INeighborhood } from 'app/shared/model/neighborhood.model';
import { DEVICESTATE } from 'app/shared/model/enumerations/devicestate.model';

export interface IDevice {
  id?: number;
  hopDepth?: number | null;
  ipv4?: string | null;
  ipv6?: string | null;
  macAddress?: string | null;
  uuid?: string | null;
  serialNumber?: string | null;
  ssid?: string | null;
  ssidPassword?: string | null;
  timezone?: string | null;
  state?: keyof typeof DEVICESTATE | null;
  installedAddress?: string | null;
  installedAddressZipcode?: string | null;
  radioType?: string | null;
  name?: string;
  dateCreated?: dayjs.Dayjs | null;
  dateUpdated?: dayjs.Dayjs | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  managed?: boolean | null;
  testDevice?: boolean | null;
  master?: boolean | null;
  lat?: string | null;
  longitude?: string | null;
  nodeMesh?: string | null;
  metrics?: IDeviceReportedMetric[] | null;
  deviceData?: IDevicePayload[] | null;
  user?: IApplicationUser | null;
  org?: IOrganization | null;
  comments?: IComment[] | null;
  location?: ILocation | null;
  mesh?: IMesh | null;
  city?: ICity | null;
  neighborhood?: INeighborhood | null;
  manager?: { id: string | number };
  subRows?: IDevice[];
}

export const defaultValue: Readonly<IDevice> = {
  managed: false,
  testDevice: false,
  master: false,
};

export type shadow = { state: { desired: { master: boolean; sprinkler: string }; reported: { master: boolean; sprinkler: string } } };
