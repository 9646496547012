import { IApplicationUser } from 'app/shared/model/application-user.model';
import { IMesh } from 'app/shared/model/mesh.model';
import { IDevice } from 'app/shared/model/device.model';
import { INeighborhood } from 'app/shared/model/neighborhood.model';

export interface IComment {
  id?: number;
  message?: string | null;
  user?: IApplicationUser | null;
  mesh?: IMesh | null;
  device?: IDevice | null;
  neighborhood?: INeighborhood | null;
}

export const defaultValue: Readonly<IComment> = {};
