import React from 'react';

function DeviceSetting() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g id="Desktop Settings">
        <g id="Vector">
          <path
            d="M38.9282 14C38.9282 14.7677 38.7291 15.4394 38.393 16.0004C37.6676 17.2113 36.3041 17.9058 34.9282 17.9335C33.5955 17.9603 32.2511 17.3615 31.4635 16.0003C31.123 15.4119 30.9282 14.7287 30.9282 14C30.9282 13.2713 31.123 12.5881 31.4635 11.9997C32.1552 10.8042 33.4478 10 34.9282 10C36.4086 10 37.7012 10.8043 38.3929 11.9997C38.7333 12.5881 38.9282 13.2713 38.9282 14Z"
            fill="#48BB78"
          />
          <path
            d="M20 8H12C9.79086 8 8 9.79086 8 12V28C8 30.2091 9.79086 32 12 32H36C38.2091 32 40 30.2091 40 28V26M34.9282 10C36.4086 10 37.7012 10.8043 38.3929 11.9997M34.9282 10C33.4478 10 32.1552 10.8042 31.4635 11.9997M34.9282 10V6M34.9282 22V17.9335M41.8564 10L38.3929 11.9997M28 18L31.4635 16.0003M41.8564 18L38.393 16.0004M28 10L31.4635 11.9997M31.4635 16.0003C31.123 15.4119 30.9282 14.7287 30.9282 14C30.9282 13.2713 31.123 12.5881 31.4635 11.9997M31.4635 16.0003C32.2511 17.3615 33.5955 17.9603 34.9282 17.9335M34.9282 17.9335C36.3041 17.9058 37.6676 17.2113 38.393 16.0004M38.393 16.0004C38.7291 15.4394 38.9282 14.7677 38.9282 14C38.9282 13.2713 38.7333 12.5881 38.3929 11.9997M24 32V40M16 40H32"
            stroke="#48BB78"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default DeviceSetting;
