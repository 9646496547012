export interface IDeviceLocation {
  id: number | null;
  deviceId: number | null;
  ownerAddress: string | null;
  ownerNeighborhood: string | null;
  ownerCity: string | null;
  ownerCounty: string | null;
  ownerState: string | null;
  ownerZipCode: string | null;
  deviceAddress: string | null;
  deviceNeighborhood: string | null;
  deviceCity: string | null;
  deviceCounty: string | null;
  deviceState: string | null;
  deviceZipCode: string | null;
}

export const defaultValue: Readonly<IDeviceLocation> = {
  id: null,
  deviceId: null,
  ownerAddress: '',
  ownerNeighborhood: '',
  ownerCity: '',
  ownerCounty: '',
  ownerState: '',
  ownerZipCode: '',
  deviceAddress: '',
  deviceNeighborhood: '',
  deviceCity: '',
  deviceCounty: '',
  deviceState: '',
  deviceZipCode: '',
};
