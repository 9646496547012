import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import { ApplicationUserWrapper } from './application-user';
import ApplicationUserDeleteDialog from './application-user-delete-dialog';
import ApplicationUserDetail from './application-user-detail';
import { ApplicationUserUpdateWrapper } from './application-user-update';
import { InviteUserWrapper } from './invite-user';

const ApplicationUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApplicationUserWrapper />} />
    <Route path="new" element={<InviteUserWrapper />} />
    <Route path=":id">
      <Route index element={<ApplicationUserDetail />} />
      <Route path="edit" element={<ApplicationUserUpdateWrapper />} />
      <Route path="delete" element={<ApplicationUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApplicationUserRoutes;
