import React from 'react';
import Loadable from 'react-loadable';
import { Navigate } from 'react-router-dom';

import { AUTHORITIES } from 'app/config/constants';
import EntitiesRoutes from 'app/entities/routes';
import Activate from 'app/modules/account/activate/activate';
import { PasswordResetFinishPage } from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import Register from 'app/modules/account/register/register';
import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { useAppSelector } from './config/store';

const loading = <div>loading ...</div>;

 const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

 const LandingPage = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const auth = isAuthenticated;
  return auth ? <Navigate to="/device" /> : <Navigate to="/login" />;
};

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

export const dataroutes = [
  { index: true, element: <LandingPage /> },
  { path: 'login', element: <Login /> },
  { path: 'logout', element: <Logout /> },
  {
    path: 'account',
    children: [
      {
        path: '*',
        element: (
          <PrivateRoute
            hasAnyAuthorities={[
              AUTHORITIES.ADMIN,
              AUTHORITIES.SUPERADMIN,
              AUTHORITIES.USER,
              AUTHORITIES.DEVICE_OWNER,
              AUTHORITIES.UTILITY_ADMIN,
              AUTHORITIES.UTILITY_USER,
            ]}
          >
            <Account />
          </PrivateRoute>
        ),
      },
      { path: 'register', element: <Register /> },
      { path: 'activate', element: <Activate /> },
      {
        path: 'reset',
        children: [
          { path: 'request', element: <PasswordResetInit /> },
          { path: 'finish', element: <PasswordResetFinishPage /> },
        ],
      },
    ],
  },
  {
    path: 'admin/*',
    element: (
      <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN]}>
        <Admin />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    element: (
      <PrivateRoute
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.SUPERADMIN,
          AUTHORITIES.DEVICE_OWNER,
          AUTHORITIES.UTILITY_ADMIN,
          AUTHORITIES.UTILITY_USER,
        ]}
      >
        <EntitiesRoutes />
      </PrivateRoute>
    ),
  },
  { path: '*', element: <PageNotFound /> },
];
