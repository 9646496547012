import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DevicePayload from './device-payload';
import DevicePayloadDetail from './device-payload-detail';
import DevicePayloadUpdate from './device-payload-update';
import DevicePayloadDeleteDialog from './device-payload-delete-dialog';

const DevicePayloadRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DevicePayload />} />
    <Route path="new" element={<DevicePayloadUpdate />} />
    <Route path=":id">
      <Route index element={<DevicePayloadDetail />} />
      <Route path="edit" element={<DevicePayloadUpdate />} />
      <Route path="delete" element={<DevicePayloadDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DevicePayloadRoutes;
