/* eslint no-console: off */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { getEntity } from '../device/device.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useParams } from 'react-router';
import moment from 'moment';
import { orderBy } from 'lodash';

const API_URL = '/data/api/device-reported-metrics';
const GRAPH_UPDATE_DELAY = null;

export const useGraphDelta = ({
  updateOnce = false,
  enabled = true,
  type,
  timeRange,
  deviceId,
}: {
  updateOnce?;
  enabled?: boolean;
  type: string;
  timeRange?: string;
  deviceId?: string;
}) => {
  const { id } = useParams<'id'>() as any;
  const device = useAppSelector(state => state.device.entity);

  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<Error>(null);
  const intervalRef = useRef<number | undefined>();

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!type || !device) {
      return;
    }

    const getTimeSeries = async () => {
      try {
        if (id === device?.id?.toString()) {
          if (updateOnce && isFetched) {
            return;
          }
          const endTime = moment()?.toISOString();
          const startTime = moment(new Date()).subtract(7, 'days').toISOString();
          const graphResponse = await axios.get(
            `${API_URL}?deviceReportedTime.greaterThan=${startTime}&deviceReportedTime.lessThan=${endTime}&type.contains=${type}&deviceId.equals=${id}&size=1000000000`,
          );

          setIsFetched(true);

          if (graphResponse) {
            const result = graphResponse?.data?.map(d => {
              return {
                ...d,
                date: moment(d.deviceReportedTime).format('MM/DD'),
              };
            });
            setData(
              orderBy(
                result,
                [
                  item => {
                    const deviceReportedTime = new Date(item.deviceReportedTime);
                    return deviceReportedTime;
                  },
                ],
                ['desc'],
              ),
            );
          }
        }
      } catch (e) {
        setData(null);
        setError(e);
      }
    };

    getTimeSeries();

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    /* intervalRef.current = setInterval(() => {
      getTimeSeries();
    }, GRAPH_UPDATE_DELAY)*/

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [timeRange, device]);

  return { data, error };
};
