import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useMemo, useState } from 'react';
import { getPaginationState, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Card } from 'reactstrap';
import { getEntitiesView, reset } from '../device/device.reducer';

import { createColumnHelper } from '@tanstack/react-table';
import ReactTable from 'app/modules/common/table';
import AddCircle from 'app/shared/icons/add-circle';
import RemoveCircle from 'app/shared/icons/remove-circle';
import { getDevicesByMaster, getEntities } from '../device/device.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { HIVE_TABLE_FILTER_QUERY } from './utils';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';

export const HiveGrid = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );

  const deviceList = useAppSelector(state => state.device.entities);
  const loading = useAppSelector(state => state.device.loading);
  const totalItems = useAppSelector(state => state.device.totalItems);
  const applicationUser = useAppSelector(state => state.authentication?.internalUser);
  const columnHelper = createColumnHelper();
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const [expanded, setExpanded] = useState({});

  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: HIVE_TABLE_FILTER_QUERY,
    pagination: paginationState,
    setPagination: setPaginationState,
    setExpanded,
  });

  const getAllEntities = () => {
    let filterUrl = 'nodesCount.greaterThan=0&';
    if (authorities.some(r => [AUTHORITIES.DEVICE_OWNER].includes(r))) {
      filterUrl = filterUrl + `userId.equals=${applicationUser?.id}&`;
    } else if (authorities.some(r => [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER].includes(r))) {
      filterUrl = filterUrl + `organizationId.equals=${applicationUser?.organization?.id}&`;
    }
    dispatch(
      getEntitiesView({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (!applicationUser && !deviceList) {
      return;
    }
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, applicationUser, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('meshName', {
        header: 'Mesh Name',
        cell(col: any) {
          const { row } = col;
          // const lastRow = row.getParentRow()?.original?.subRows?.length - 1 === row.index;
          const isParent = !!row?.original?.subRows;
          const canExpand = Number(row?.original?.nodesCount) > 0;
          return (
            <div
              data-testid="expandable-icon-div"
              style={{
                paddingLeft: `${row.depth * 1.5}rem`,
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <div className="d-flex">
                {row?.original?.nodesCount ? (
                  <div
                    className="d-flex"
                    data-testid="expandable-icon"
                    {...{
                      onClick() {
                        if (row?.original?.subRows?.length === 0) {
                          dispatch(
                            getDevicesByMaster({
                              page: 0,
                              size: 1000000,
                              sort: 'id,desc',
                              filterUrl: `meshId.equals=${row.original.id}&`,
                            }),
                          ).then(result => {
                            setExpanded({ ...expanded, [row.id]: !row.getIsExpanded() });
                          });
                        } else {
                          setExpanded({ ...expanded, [row.id]: !row.getIsExpanded() });
                        }
                      },
                    }}
                  >
                    {canExpand && (
                      <>
                        {/* <div className="expandable-wrapper-div">
                          <div id="expandable-row-parent" className={row.getIsExpanded() ? 'apply-borders' : ''} />
                         </div> */}
                        <span data-testid="expansion-toggle" id="expansion-toggle">
                          {row.getIsExpanded() ? <RemoveCircle /> : <AddCircle />}
                        </span>
                      </>
                    )}
                    <div className="deviceNameCell" style={!canExpand ? { marginLeft: '12px' } : {}}>
                      {isParent ? row?.original?.meshName : ''}
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <div className="expandable-wrapper-div">
                      <div style={{ height: lastRow ? '30px' : '60px' }} id="expandable-row-child">
                        {row?.depth > 0 && (
                          <span>
                            <hr />
                          </span>
                        )}
                      </div>
                      </div> */}
                    <div className={'deviceNameCell'} style={{ marginLeft: row?.depth > 0 ? '1rem' : '2.4rem' }}>
                      {isParent ? row?.original?.meshName : ''}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        },
        size: 150,
        meta: { index: 0 },
      }),
      columnHelper.accessor('serialNumber', {
        header: translate('rainspotApp.device.newSerialNumber'),
        cell(col: any) {
          const { row } = col;
          return row?.original?.subRows ? '' : row?.original?.serialNumber + (row?.original?.master ? ' (Master)' : '');
        },
      }),
      columnHelper.accessor('installedAddress', {
        header: translate('rainspotApp.device.address'),
        cell(col: any) {
          const { row } = col;
          return row.original?.deviceLocation?.deviceAddress ? row.original?.deviceLocation?.deviceAddress : '---';
        },
      }),
      columnHelper.accessor('nodesCount', {
        header: translate('rainspotApp.device.nodesCount'),
      }),
      columnHelper.accessor('nodePosition', {
        cell(col: any) {
          const { row } = col;
          return row.original?.nodePosition ? row.original?.nodePosition : '---';
        },
        header: translate('rainspotApp.device.nodesPositioning'),
      }),
      columnHelper.accessor('state', {
        cell(col: any) {
          const { row } = col;
          const isParent = !!row?.original?.subRows;
          return (
            <>
              {!isParent ? (
                <Badge color={col?.row?.original?.state === 'ONLINE' ? 'success' : 'danger'}>{col?.row?.original?.state}</Badge>
              ) : null}
            </>
          );
        },
        header: translate('rainspotApp.device.state'),
        size: 120,
      }),
    ];
  }, [deviceList, setExpanded, expanded]);

  return (
    <Card className="jh-card">
      <ReactTable
        columns={columns}
        data={deviceList}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        isExpandible={true}
        expandedState={expanded}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        onRowClick={(e, row) => {
          const id = row?.original?.id;
          navigate(`/device/${id}/edit`, { state: { from: { path: '/hive' } } });
        }}
      ></ReactTable>
    </Card>
  );
};

export default HiveGrid;
