import React from 'react';

function InventoryIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99996 10.6667V13.3333M5.33329 13.3333H10.6666M3.99996 10.6667H12C12.7363 10.6667 13.3333 10.0697 13.3333 9.33332V3.99999C13.3333 3.26361 12.7363 2.66666 12 2.66666H3.99996C3.26358 2.66666 2.66663 3.26361 2.66663 3.99999V9.33332C2.66663 10.0697 3.26358 10.6667 3.99996 10.6667Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InventoryIcon;
