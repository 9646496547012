import React from 'react';

function CrossIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" className={className}>
      <g clipPath="url(#clip0_2682_17724)">
        <path d="M6.66634 6.66683L1.33301 1.3335M6.66634 1.3335L1.33301 6.66683" stroke="#439A97" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_2682_17724">
          <rect width="8" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CrossIcon;
