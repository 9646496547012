import dayjs from 'dayjs';
import { IDevice } from 'app/shared/model/device.model';

export interface IDeviceReportedMetric {
  id?: number;
  attribute?: string;
  value?: string;
  deviceReportedTime?: dayjs.Dayjs | null;
  device?: IDevice | null;
  deviceSerial?: string;
  neighbourhoodName?: string;
  organizationName?: string;
}

export const defaultValue: Readonly<IDeviceReportedMetric> = {};
