import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './metric.reducer';

export const MetricDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const metricEntity = useAppSelector(state => state.metric.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="metricDetailsHeading">
          <Translate contentKey="rainspotApp.metric.detail.title">Metric</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{metricEntity.id}</dd>
          <dt>
            <span id="attribute">
              <Translate contentKey="rainspotApp.metric.attribute">Attribute</Translate>
            </span>
          </dt>
          <dd>{metricEntity.attribute}</dd>
          <dt>
            <span id="type1">
              <Translate contentKey="rainspotApp.metric.type1">Type 1</Translate>
            </span>
          </dt>
          <dd>{metricEntity.type1}</dd>
          <dt>
            <span id="value">
              <Translate contentKey="rainspotApp.metric.value">Value</Translate>
            </span>
          </dt>
          <dd>{metricEntity.value}</dd>
          <dt>
            <span id="dateCreated">
              <Translate contentKey="rainspotApp.metric.dateCreated">Date Created</Translate>
            </span>
          </dt>
          <dd>{metricEntity.dateCreated ? <TextFormat value={metricEntity.dateCreated} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="dateUpdated">
              <Translate contentKey="rainspotApp.metric.dateUpdated">Date Updated</Translate>
            </span>
          </dt>
          <dd>{metricEntity.dateUpdated ? <TextFormat value={metricEntity.dateUpdated} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="rainspotApp.metric.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{metricEntity.createdBy}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="rainspotApp.metric.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{metricEntity.modifiedBy}</dd>
          <dt>
            <span id="deviceReportedTime">
              <Translate contentKey="rainspotApp.metric.deviceReportedTime">Device Reported Time</Translate>
            </span>
          </dt>
          <dd>
            {metricEntity.deviceReportedTime ? (
              <TextFormat value={metricEntity.deviceReportedTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="rainspotApp.metric.device">Device</Translate>
          </dt>
          <dd>{metricEntity.device ? metricEntity.device.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/metric" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/metric/${metricEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MetricDetail;
