import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ConfirmationBox } from 'app/modules/common/confirmation-box';
import { PasswordInput } from 'app/modules/login/password-field';
import { BrandIcon, BrandIconLarge } from 'app/shared/layout/header/header-components';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useForm } from 'react-hook-form';
import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import { PasswordText } from '../../register/register';
import CheckCircle from 'app/shared/icons/check-circle';

export const PasswordResetFinishPage = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  const [password, setPassword] = useState('');
  const [loadModal, setLoadModal] = useState(false);
  const {
    register,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });

  useEffect(
    () => () => {
      dispatch(reset());
      setLoadModal(false);
    },
    [],
  );

  const handleValidSubmit = values => dispatch(handlePasswordResetFinish({ key, newPassword: values?.newPassword }));

  const updatePassword = val => setPassword(val);

  const getResetForm = () => {
    return (
      <Form onSubmit={vals => void handleSubmit(handleValidSubmit)(vals)}>
        <PasswordInput
          errors={errors}
          touchedFields={touchedFields}
          label={'New Password'}
          register={register}
          name={'newPassword'}
          onChange={updatePassword}
          validate={{
            required: { value: true, message: translate('global.messages.validate.newpassword.required') },
            minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
            maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
          }}
          dataCy="resetPassword"
        />

        <PasswordInput
          errors={errors}
          touchedFields={touchedFields}
          label={translate('global.form.confirmpassword.label')}
          register={register}
          name={'confirmPassword'}
          validate={{
            required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
            minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
            maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
            validate: v => v === password || translate('global.messages.error.dontmatch'),
          }}
          dataCy="confirmResetPassword"
        />
        <PasswordStrengthBar password={password} />
        <PasswordText />
        <div className={'w-100 pl-40 pr-40 sign-in'}>
          <Button color="primary" className="w-100" type="submit" data-cy="submit">
            <Translate contentKey="reset.finish.form.confirm">Confirm</Translate>
          </Button>
        </div>
      </Form>
    );
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      setLoadModal(true);
    }
  }, [successMessage]);

  return (
    <>
      {!loadModal && (
        <Card className="login-form-card">
          <div id="login-form">
            <div id="login-title" className="login-logo" data-cy="loginTitle">
              <BrandIconLarge imgH={'155px'} />
            </div>
            <div className="mt-10">&nbsp;</div>
            <Link to="/login" data-cy="backToLogin" className="primary-link">
              {'<'}
              &nbsp;
              <Translate contentKey="reset.request.back">Back To Login?</Translate>
            </Link>
            <div className="mt-10">&nbsp;</div>
            <Row className="justify-content-center">
              <Col md="12">
                <h3>
                  <Translate contentKey="reset.finish.title">Reset password</Translate>
                </h3>
                <div>{getResetForm()}</div>
              </Col>
            </Row>
          </div>
        </Card>
      )}
      {loadModal && (
        <ConfirmationBox
          message={'You have successfully reset your password'}
          buttonLink={'/login'}
          buttonText={'Login'}
          icon={<CheckCircle />}
        ></ConfirmationBox>
      )}
    </>
  );
};

export default PasswordResetFinishPage;
