import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getViewEntities, reset } from './neighborhood.reducer';
import { reset as resetCities } from 'app/entities/city/city.reducer';
import ReactTable from 'app/modules/common/table';
import { createColumnHelper } from '@tanstack/react-table';
import { Card } from 'reactstrap';
import { formatDate } from 'app/shared/util/date-utils';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { NEIGH_TABLE_FILTER_QUERY } from './utils';

export const Neighborhood = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );
  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: NEIGH_TABLE_FILTER_QUERY,
    pagination: paginationState,
    setPagination: setPaginationState,
  });

  const neighborhoodList = useAppSelector(state => state.neighborhood.entities);
  const loading = useAppSelector(state => state.neighborhood.loading);
  const totalItems = useAppSelector(state => state.neighborhood.totalItems);

  const getAllEntities = () => {
    const filterUrl = '';
    dispatch(
      getViewEntities({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(resetCities());
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: translate('rainspotApp.neighborhood.name'),
    }),
    columnHelper.accessor(
      (col: any) => {
        return col?.stateId ? col?.stateId : '';
      },
      {
        id: 'stateId',
        header: translate('rainspotApp.neighborhood.stateN'),
      },
    ),
    // columnHelper.accessor('regionId', {
    //   header: translate('rainspotApp.neighborhood.regionId'),
    // }),
    columnHelper.accessor(
      (col: any) => {
        return col?.cityName ? col?.cityName : '';
      },
      {
        id: 'cityName',
        header: translate('rainspotApp.neighborhood.cityN'),
      },
    ),
    columnHelper.accessor('createdBy', {
      id: 'createdBy',
      header: translate('rainspotApp.neighborhood.createdBy'),
    }),
    columnHelper.accessor(
      (col: any) => {
        return col?.createdDate ? formatDate(col?.createdDate) : '';
      },
      {
        id: 'createdDate',
        header: translate('rainspotApp.neighborhood.createdDate'),
      },
    ),
  ];

  return (
    <Card className="jh-card">
      <h2 id="neighborhood-heading" data-cy="NeighborhoodHeading" data-testid="NeighborhoodHeading">
        <div className="d-flex justify-content-end">
          <Link
            to="/neighborhood/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
            data-testid="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="rainspotApp.neighborhood.home.createLabel">Create new Neighborhood</Translate>
          </Link>
        </div>
      </h2>

      <ReactTable
        columns={columns}
        data={neighborhoodList}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        onRowClick={(e, row) => {
          const id = row?.original?.id;
          navigate(`/neighborhood/${id}/edit`);
        }}
      ></ReactTable>
    </Card>
  );
};

export default Neighborhood;
