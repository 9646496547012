import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { Hive } from './hive';

const HiveUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Hive />} />
    <Route path=":id"></Route>
  </ErrorBoundaryRoutes>
);

export default HiveUserRoutes;
