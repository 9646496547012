import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './device.reducer';

export const DeviceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const deviceEntity = useAppSelector(state => state.device.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="deviceDetailsHeading">
          <Translate contentKey="rainspotApp.device.detail.title">Device</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.id}</dd>
          <dt>
            <span id="hopDepth">
              <Translate contentKey="rainspotApp.device.hopDepth">Hop Depth</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.hopDepth}</dd>
          <dt>
            <span id="ipv4">
              <Translate contentKey="rainspotApp.device.ipv4">Ipv 4</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.ipv4}</dd>
          <dt>
            <span id="ipv6">
              <Translate contentKey="rainspotApp.device.ipv6">Ipv 6</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.ipv6}</dd>
          <dt>
            <span id="macAddress">
              <Translate contentKey="rainspotApp.device.macAddress">Mac Address</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.macAddress}</dd>
          <dt>
            <span id="uuid">
              <Translate contentKey="rainspotApp.device.uuid">Uuid</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.uuid}</dd>
          <dt>
            <span id="serialNumber">
              <Translate contentKey="rainspotApp.device.serialNumber">Serial Number</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.serialNumber}</dd>
          <dt>
            <span id="ssid">
              <Translate contentKey="rainspotApp.device.ssid">Ssid</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.ssid}</dd>
          <dt>
            <span id="ssidPassword">
              <Translate contentKey="rainspotApp.device.ssidPassword">Ssid Password</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.ssidPassword}</dd>
          <dt>
            <span id="timezone">
              <Translate contentKey="rainspotApp.device.timezone">Timezone</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.timezone}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="rainspotApp.device.state">State</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.state}</dd>
          <dt>
            <span id="installedAddress">
              <Translate contentKey="rainspotApp.device.installedAddress">Installed Address</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.installedAddress}</dd>
          <dt>
            <span id="installedAddressZipcode">
              <Translate contentKey="rainspotApp.device.installedAddressZipcode">Installed Address Zipcode</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.installedAddressZipcode}</dd>
          <dt>
            <span id="radioType">
              <Translate contentKey="rainspotApp.device.radioType">Radio Type</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.radioType}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="rainspotApp.device.name">Name</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.name}</dd>
          <dt>
            <span id="dateCreated">
              <Translate contentKey="rainspotApp.device.dateCreated">Date Created</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.dateCreated ? <TextFormat value={deviceEntity.dateCreated} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="dateUpdated">
              <Translate contentKey="rainspotApp.device.dateUpdated">Date Updated</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.dateUpdated ? <TextFormat value={deviceEntity.dateUpdated} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="rainspotApp.device.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.createdBy}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="rainspotApp.device.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.modifiedBy}</dd>
          <dt>
            <span id="managed">
              <Translate contentKey="rainspotApp.device.managed">Managed</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.managed ? 'true' : 'false'}</dd>
          <dt>
            <span id="testDevice">
              <Translate contentKey="rainspotApp.device.testDevice">Test Device</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.testDevice ? 'true' : 'false'}</dd>
          <dt>
            <span id="master">
              <Translate contentKey="rainspotApp.device.master">Master</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.master ? 'true' : 'false'}</dd>
          <dt>
            <span id="lat">
              <Translate contentKey="rainspotApp.device.lat">Lat</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.lat}</dd>
          <dt>
            <span id="longitude">
              <Translate contentKey="rainspotApp.device.longitude">Longitude</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.longitude}</dd>
          <dt>
            <span id="nodeMesh">
              <Translate contentKey="rainspotApp.device.nodeMesh">Node Mesh</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.nodeMesh}</dd>
          <dt>
            <Translate contentKey="rainspotApp.device.user">User</Translate>
          </dt>
          <dd>{deviceEntity.user ? deviceEntity.user.id : ''}</dd>
          <dt>
            <Translate contentKey="rainspotApp.device.org">Org</Translate>
          </dt>
          <dd>{deviceEntity.org ? deviceEntity.org.id : ''}</dd>
          <dt>
            <Translate contentKey="rainspotApp.device.location">Location</Translate>
          </dt>
          <dd>{deviceEntity.location ? deviceEntity.location.id : ''}</dd>
          <dt>
            <Translate contentKey="rainspotApp.device.mesh">Mesh</Translate>
          </dt>
          <dd>{deviceEntity.mesh ? deviceEntity.mesh.id : ''}</dd>
          <dt>
            <Translate contentKey="rainspotApp.device.city">City</Translate>
          </dt>
          <dd>{deviceEntity.city ? deviceEntity.city.id : ''}</dd>
          <dt>
            <Translate contentKey="rainspotApp.device.neighborhood">Neighborhood</Translate>
          </dt>
          <dd>{deviceEntity.neighborhood ? deviceEntity.neighborhood.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/device" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/device/${deviceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DeviceDetail;
