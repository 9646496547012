export const readFileContent = async (file: File) => {
  if (!file) return Promise.resolve(null);

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const blob = fileReader.result as string;
      resolve(blob.split(',')[1]);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
};

export const downloadFile = url => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
