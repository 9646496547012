import BlockDesktop from 'app/shared/icons/block-desktop';
import DeviceSetting from 'app/shared/icons/desktop-setting';
import RemoveDesktop from 'app/shared/icons/remove-desktop';
import WarningIcon from 'app/shared/icons/warning';
import React, { useEffect, useMemo } from 'react';
import { SummaryCards } from './cards-summary';
import HiveGrid from './hive-grid';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSummaryCard } from '../device/device.reducer';

export const Hive = () => {
  const cardValues = useAppSelector(state => state.device.tableStats);
  const dispatch = useAppDispatch();
  const cards = useMemo(() => {
    return [
      { title: 'Online', value: cardValues?.find(c => c.state === 'ONLINE')?.count ?? 0, icon: <DeviceSetting /> },
      { title: 'Offline', value: cardValues?.find(c => c.state === 'OFFLINE')?.count ?? 0, icon: <RemoveDesktop /> },
      { title: 'Critical', value: cardValues?.find(c => c.status === 'CRITICAL')?.count ?? 0, icon: <BlockDesktop /> },
      { title: 'Warning', value: cardValues?.find(c => c.status === 'WARNING')?.count ?? 0, icon: <WarningIcon /> },
    ];
  }, [cardValues]);

  useEffect(() => {
    dispatch(getSummaryCard());
  }, []);

  return (
    <>
      <SummaryCards cards={cards} />
      <HiveGrid />
    </>
  );
};
