import React from 'react';

const MessageIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66667 4.66668L6.8 7.76667C7.51111 8.30001 8.48889 8.30001 9.2 7.76667L13.3333 4.66664M3.33333 12.6667H12.6667C13.403 12.6667 14 12.0697 14 11.3333V4.66668C14 3.9303 13.403 3.33334 12.6667 3.33334H3.33333C2.59695 3.33334 2 3.9303 2 4.66668V11.3333C2 12.0697 2.59695 12.6667 3.33333 12.6667Z"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default MessageIcon;
