import React from 'react';

function BlockDesktop() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M40 13C40 14.933 39.2165 16.683 37.9497 17.9497C36.683 19.2165 34.933 20 33 20C29.134 20 26 16.866 26 13C26 11.067 26.7835 9.317 28.0503 8.05025C29.317 6.7835 31.067 6 33 6C36.866 6 40 9.13401 40 13Z"
        fill="white"
      />
      <path
        d="M18 8H12C9.79086 8 8 9.79086 8 12V28C8 30.2091 9.79086 32 12 32H36C38.2091 32 40 30.2091 40 28V24M28.0503 8.05025C26.7835 9.317 26 11.067 26 13C26 16.866 29.134 20 33 20C34.933 20 36.683 19.2165 37.9497 17.9497M28.0503 8.05025C29.317 6.7835 31.067 6 33 6C36.866 6 40 9.13401 40 13C40 14.933 39.2165 16.683 37.9497 17.9497M28.0503 8.05025L37.9497 17.9497M24 32V40M16 40H32"
        stroke="#ED8936"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BlockDesktop;
