import { IDevice } from 'app/shared/model/device.model';
import { IComment } from 'app/shared/model/comment.model';
import { INeighborhood } from 'app/shared/model/neighborhood.model';

export interface IMesh {
  id?: number;
  name?: string | null;
  subnet?: string;
  numberOfNodes?: number;
  uptime?: string | null;
  jitter?: number | null;
  latency?: number | null;
  bandwidth?: number | null;
  throughput?: number | null;
  packetloss?: number | null;
  numberOfPacketsReceived?: number | null;
  numberOfPacketsTransmitted?: number | null;
  packetRetransmittion?: number | null;
  masterNodeSerialNumber?: string | null;
  masterNodeMacAddress?: string | null;
  masterNodeIpv4?: string | null;
  masterNodeIpv6?: string | null;
  nodes?: IDevice[] | null;
  comments?: IComment[] | null;
  neighborhood?: INeighborhood | null;
}

export const defaultValue: Readonly<IMesh> = {};
