import Settings from 'app/shared/icons/settings';
import SignOut from 'app/shared/icons/sign-out';
import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';

export const accountMenuItemsAuthenticated = ({ setIsOpen }) => (
  <>
    <Link
      to="/account/settings"
      data-cy="settings"
      data-testid="settings"
      style={{ width: 150, height: 25 }}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <Settings />
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </Link>
    <Link to="/logout" data-cy="logout" data-testid="logout" style={{ width: 150, height: 25 }}>
      <SignOut />
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </Link>
  </>
);

export const accountMenuItems = () => (
  <>
    <Link id="login-item" to="/login" data-cy="login" data-testid="login">
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </Link>
    <Link to="/account/register" data-cy="register" data-testid="register">
      <Translate contentKey="global.menu.account.register">Register</Translate>
    </Link>
  </>
);

export const AccountMenu = ({ isAuthenticated = false, account }: { isAuthenticated?: boolean; account?: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="account-menu" data-testid="accountMenu" id="account-menu" data-cy="accountMenu">
        <span> {account?.firstName}</span>
      </div>
      <Popover
        target="account-menu"
        placement="bottom"
        className="account-menu-popover"
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        trigger="legacy"
      >
        <PopoverBody className="account-menu-popover-body">
          <div className="d-flex flex-column gap-1">
            {isAuthenticated && accountMenuItemsAuthenticated({ setIsOpen })}
            {!isAuthenticated && accountMenuItems()}
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default AccountMenu;
