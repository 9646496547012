import React from 'react';

function AddUserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="User Add">
        <path
          id="Vector"
          d="M2.66663 13.3333V12.6667C2.66663 10.8257 4.15901 9.33333 5.99996 9.33333H8.49996M11.6903 9.30965V11.6667M11.6903 11.6667V14.0237M11.6903 11.6667H14.0473M11.6903 11.6667H9.33328M9.99996 4.66667C9.99996 6.13943 8.80605 7.33333 7.33329 7.33333C5.86053 7.33333 4.66663 6.13943 4.66663 4.66667C4.66663 3.19391 5.86053 2 7.33329 2C8.80605 2 9.99996 3.19391 9.99996 4.66667Z"
          stroke="#439A97"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default AddUserIcon;
