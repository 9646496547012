import { INeighborhood } from 'app/shared/model/neighborhood.model';
import { IDevice } from 'app/shared/model/device.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { Region } from 'app/shared/model/enumerations/region.model';

export interface IState {
  id?: number;
  name?: string;
  region?: keyof typeof Region;
  state?: string;
  county?: string;
  neighberhoods?: INeighborhood[] | null;
  devices?: IDevice[] | null;
  organization?: IOrganization | null;
}

export const defaultValue: Readonly<IState> = {};
