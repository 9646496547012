import React from 'react';

function SignOut() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M6.125 11.8125C6.125 11.9285 6.07891 12.0398 5.99686 12.1219C5.91481 12.2039 5.80353 12.25 5.6875 12.25H2.625C2.39294 12.25 2.17038 12.1578 2.00628 11.9937C1.84219 11.8296 1.75 11.6071 1.75 11.375V2.625C1.75 2.39294 1.84219 2.17038 2.00628 2.00628C2.17038 1.84219 2.39294 1.75 2.625 1.75H5.6875C5.80353 1.75 5.91481 1.79609 5.99686 1.87814C6.07891 1.96019 6.125 2.07147 6.125 2.1875C6.125 2.30353 6.07891 2.41481 5.99686 2.49686C5.91481 2.57891 5.80353 2.625 5.6875 2.625H2.625V11.375H5.6875C5.80353 11.375 5.91481 11.4211 5.99686 11.5031C6.07891 11.5852 6.125 11.6965 6.125 11.8125ZM12.122 6.69047L9.93453 4.50297C9.85244 4.42088 9.7411 4.37476 9.625 4.37476C9.5089 4.37476 9.39756 4.42088 9.31547 4.50297C9.23338 4.58506 9.18726 4.6964 9.18726 4.8125C9.18726 4.9286 9.23338 5.03994 9.31547 5.12203L10.7565 6.5625H5.6875C5.57147 6.5625 5.46019 6.60859 5.37814 6.69064C5.29609 6.77269 5.25 6.88397 5.25 7C5.25 7.11603 5.29609 7.22731 5.37814 7.30936C5.46019 7.39141 5.57147 7.4375 5.6875 7.4375H10.7565L9.31547 8.87797C9.23338 8.96006 9.18726 9.0714 9.18726 9.1875C9.18726 9.3036 9.23338 9.41494 9.31547 9.49703C9.39756 9.57912 9.5089 9.62524 9.625 9.62524C9.7411 9.62524 9.85244 9.57912 9.93453 9.49703L12.122 7.30953C12.1627 7.2689 12.195 7.22065 12.217 7.16754C12.239 7.11442 12.2503 7.05749 12.2503 7C12.2503 6.94251 12.239 6.88558 12.217 6.83246C12.195 6.77935 12.1627 6.7311 12.122 6.69047Z"
        fill="#4A5568"
      />
    </svg>
  );
}

export default SignOut;
