import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from '../../device-reported-metric/device-reported-metric.reducer';
import { formatDateWithTime } from 'app/shared/util/date-utils';

export const DevicePayload = () => {
  const dispatch = useAppDispatch();
  const deviceMetrics = useAppSelector(state => state.deviceReportedMetric.payload);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(
      getEntities({
        page: 0,
        size: 10,
        sort: `id,desc`,
        filterUrl: `deviceId.equals=${id}&`,
      }),
    );
  }, []);

  return (
    <div className="payload-tab">
      {deviceMetrics?.length === 0 ? <div className="no-data">No data found</div> : <></>}
      {deviceMetrics?.map((metric, index) => (
        <>
          <div className="payload-time" data-testid={'time-' + index}>
            {formatDateWithTime(metric?.deviceReportedTime)}
          </div>
          <div className="payload-content" data-testid={'content-' + index}>
            {JSON.stringify(metric?.value)}
          </div>
          {index === deviceMetrics?.length - 1 ? <></> : <hr data-testid={'dash-' + index} />}
        </>
      ))}
    </div>
  );
};

export default DevicePayload;
