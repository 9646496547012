import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Metric from './metric';
import MetricDetail from './metric-detail';
import MetricUpdate from './metric-update';
import MetricDeleteDialog from './metric-delete-dialog';

const MetricRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Metric />} />
    <Route path="new" element={<MetricUpdate />} />
    <Route path=":id">
      <Route index element={<MetricDetail />} />
      <Route path="edit" element={<MetricUpdate />} />
      <Route path="delete" element={<MetricDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MetricRoutes;
