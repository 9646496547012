import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Card, Label, InputGroup, FormFeedback } from 'reactstrap';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { getEntities as getStates } from 'app/entities/city/state.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCountyEntities, getEntities as getCityEntities } from 'app/entities/city/city.reducer';
import { getEntity, updateEntity, createEntity, reset } from './neighborhood.reducer';
import { Controller, FieldError, useForm } from 'react-hook-form';
import Select from 'react-select';
import { MAX_SIZE, getFilterByStateAndCounty, reactStrapStyles } from 'app/shared/util/form-utils';
import _ from 'lodash';

export const NeighborhoodUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const states = useAppSelector(state => state.states.menuOptions);
  const neighborhoodEntity = useAppSelector(state => state.neighborhood.entity);
  const loading = useAppSelector(state => state.neighborhood.loading);
  const updating = useAppSelector(state => state.neighborhood.updating);
  const updateSuccess = useAppSelector(state => state.neighborhood.updateSuccess);
  const countiesOptions = useAppSelector(state => state.city.countyMenuOptions);
  const citiesOptions = useAppSelector(state => state.city.menuOptions);

  const handleClose = () => {
    navigate('/neighborhood' + location.search);
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
    reset: resetForm,
    watch,
  } = useForm();

  const watchState = watch('stateId');
  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getStates({}));
    } else {
      dispatch(getEntity(id));
      dispatch(getStates({}));
    }
  }, []);

  useEffect(() => {
    if (neighborhoodEntity?.id) {
      const filterUrl = getFilterByStateAndCounty(neighborhoodEntity.stateId, neighborhoodEntity.countId);
      dispatch(getCountyEntities(neighborhoodEntity.stateId));
      dispatch(getCityEntities({ filterUrl, size: MAX_SIZE }));
    }
  }, [neighborhoodEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...neighborhoodEntity,
      ...values,
      city: {
        id: values?.city?.value,
      },
      countId: values?.countyId?.label,
      stateId: values?.stateId?.label,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = useMemo(
    () =>
      isNew
        ? {}
        : {
            ...neighborhoodEntity,
            city: { label: neighborhoodEntity?.city?.name, value: neighborhoodEntity?.city?.id },
            countyId: { label: neighborhoodEntity?.countId, value: neighborhoodEntity?.countId },
            stateId: states?.find(c => c.value === neighborhoodEntity.stateId),
          },
    [neighborhoodEntity, states],
  );
  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [resetForm, defaultValues]);

  return (
    <Card className="jh-card">
      <Row className="justify-content-start">
        <Col md="12">
          <h2 id="rainspotApp.neighborhood.home.createOrEditLabel" data-cy="NeighborhoodCreateUpdateHeading">
            <Translate contentKey={isNew ? `rainspotApp.neighborhood.home.createLabel` : `rainspotApp.neighborhood.home.editLabel`}>
              Create or edit a Neighborhood
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="mt-10">&nbsp;</div>
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
              <div className="form-body">
                <div className="d-flex">
                  <ValidatedField
                    register={register}
                    type="text"
                    id="neighborhood-name"
                    data-cy="name"
                    name="name"
                    error={errors?.name as FieldError}
                    label={translate('rainspotApp.neighborhood.name')}
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                </div>

                <div className="d-flex">
                  <div style={{ display: 'flex' }} className="flex-column">
                    <Label for="stateId">{'State'}*</Label>
                    <InputGroup className="mb-3" error={errors?.stateId} data-testid="stateId">
                      <Controller
                        control={control}
                        name="stateId"
                        rules={{ required: 'State is required' }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Select
                                menuPlacement="auto"
                                classNamePrefix="state"
                                options={states}
                                styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                                value={field.value}
                                onChange={(e: any) => {
                                  field.onChange(e);
                                  dispatch(getCountyEntities(e?.value));
                                  setValue('countyId', null);
                                  setValue('city', null);
                                }}
                              />

                              <FormFeedback>{fieldState.error?.message}</FormFeedback>
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </div>

                  <div style={{ display: 'flex' }} className="flex-column">
                    <Label for="countyId">{'County'}*</Label>
                    <InputGroup className="mb-3" error={errors?.countyId} data-testid="county">
                      <Controller
                        control={control}
                        name="countyId"
                        rules={{ required: 'County is required' }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Select
                                classNamePrefix="county"
                                menuPlacement="auto"
                                options={countiesOptions || []}
                                styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                                value={field.value}
                                onChange={(e: any) => {
                                  const filterUrl = getFilterByStateAndCounty(watchState?.value, e?.value);
                                  field.onChange(e);
                                  dispatch(getCityEntities({ filterUrl }));
                                  setValue('city', null);
                                }}
                              />

                              <FormFeedback>{fieldState.error?.message}</FormFeedback>
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </div>

                  <div style={{ display: 'flex' }} className="flex-column">
                    <Label for="city">{'City'}*</Label>
                    <InputGroup className="mb-3" error={errors?.countId} data-testid="city">
                      <Controller
                        control={control}
                        name="city"
                        rules={{ required: 'City is required' }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Select
                                menuPlacement="auto"
                                classNamePrefix="city"
                                options={citiesOptions}
                                styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                                value={field.value}
                                onChange={(file: any) => {
                                  field.onChange(file);
                                }}
                              />

                              <FormFeedback>{fieldState.error?.message}</FormFeedback>
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
              <hr />
              <div className="justify-content-end d-flex form-footer">
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/neighborhood" replace color="info">
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.cancel"></Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default NeighborhoodUpdate;
