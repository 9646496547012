import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ReactTable from 'app/modules/common/table';
import { formatDate } from 'app/shared/util/date-utils';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Translate, getPaginationState } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { getViewEntities, reset } from './device-reported-metric.reducer';
import { METRICS_TABLE_QUERY_KEYS } from './utils';

export const Neighborhood = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );
  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: METRICS_TABLE_QUERY_KEYS,
    pagination: paginationState,
    setPagination: setPaginationState,
  });

  const metrics = useAppSelector(state => state.deviceReportedMetric.entities);
  const loading = useAppSelector(state => state.deviceReportedMetric.loading);
  const totalItems = useAppSelector(state => state.deviceReportedMetric.totalItems);

  const getAllEntities = () => {
    const filterUrl = '';
    dispatch(
      getViewEntities({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const columns = [
    columnHelper.accessor('serialNo', {
      id: 'serialNo',
      header: 'Serial No.',
    }),
    columnHelper.accessor('attribute', {
      id: 'attribute',
      header: 'Attribute',
    }),
    columnHelper.accessor('value', {
      id: 'value',
      header: 'Value',
    }),
    columnHelper.accessor('organizationName', {
      id: 'organizationName',
      header: 'Organization',
    }),
    columnHelper.accessor('neighbourhoodName', {
      id: 'neighbourhoodName',
      header: 'Neighborhood',
    }),
    columnHelper.accessor('deviceReportedTime', {
      cell(col: any) {
        return col?.row?.original?.deviceReportedTime ? formatDate(col.row.original.deviceReportedTime) : '';
      },
      header: 'Reported Date',
    }),
  ];

  return (
    <Card className="jh-card">
      <div className="d-flex justify-content-end">
        <Button color="info" className="me-2" onClick={handleSyncList} style={{ pointerEvents: loading ? 'none' : 'all' }}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="rainspotApp.deviceReportedMetric.home.refreshListLabel">Refresh List</Translate>
        </Button>
      </div>
      <ReactTable
        columns={columns}
        data={isArray(metrics) ? metrics : []}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      ></ReactTable>
    </Card>
  );
};

export default Neighborhood;
