import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDevice } from 'app/shared/model/device.model';
import { getEntities as getDevices } from 'app/entities/device/device.reducer';
import { IDevicePayload } from 'app/shared/model/device-payload.model';
import { PayloadDirection } from 'app/shared/model/enumerations/payload-direction.model';
import { getEntity, updateEntity, createEntity, reset } from './device-payload.reducer';

export const DevicePayloadUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const devices = useAppSelector(state => state.device.entities);
  const devicePayloadEntity = useAppSelector(state => state.devicePayload.entity);
  const loading = useAppSelector(state => state.devicePayload.loading);
  const updating = useAppSelector(state => state.devicePayload.updating);
  const updateSuccess = useAppSelector(state => state.devicePayload.updateSuccess);
  const payloadDirectionValues = Object.keys(PayloadDirection);

  const handleClose = () => {
    navigate('/device-payload' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDevices({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.timeOfOrigin = convertDateTimeToServer(values.timeOfOrigin);
    values.timeOfIngestion = convertDateTimeToServer(values.timeOfIngestion);

    const entity = {
      ...devicePayloadEntity,
      ...values,
      device: devices.find(it => it.id.toString() === values.device.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          timeOfOrigin: displayDefaultDateTime(),
          timeOfIngestion: displayDefaultDateTime(),
        }
      : {
          direction: 'CLOUDTODEVICE',
          ...devicePayloadEntity,
          timeOfOrigin: convertDateTimeFromServer(devicePayloadEntity.timeOfOrigin),
          timeOfIngestion: convertDateTimeFromServer(devicePayloadEntity.timeOfIngestion),
          device: devicePayloadEntity?.device?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rainspotApp.devicePayload.home.createOrEditLabel" data-cy="DevicePayloadCreateUpdateHeading">
            <Translate contentKey="rainspotApp.devicePayload.home.createOrEditLabel">Create or edit a DevicePayload</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="device-payload-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rainspotApp.devicePayload.attribute')}
                id="device-payload-attribute"
                name="attribute"
                data-cy="attribute"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('rainspotApp.devicePayload.direction')}
                id="device-payload-direction"
                name="direction"
                data-cy="direction"
                type="select"
              >
                {payloadDirectionValues.map(payloadDirection => (
                  <option value={payloadDirection} key={payloadDirection}>
                    {translate('rainspotApp.PayloadDirection.' + payloadDirection)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('rainspotApp.devicePayload.value')}
                id="device-payload-value"
                name="value"
                data-cy="value"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('rainspotApp.devicePayload.timeOfOrigin')}
                id="device-payload-timeOfOrigin"
                name="timeOfOrigin"
                data-cy="timeOfOrigin"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('rainspotApp.devicePayload.timeOfIngestion')}
                id="device-payload-timeOfIngestion"
                name="timeOfIngestion"
                data-cy="timeOfIngestion"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="device-payload-device"
                name="device"
                data-cy="device"
                label={translate('rainspotApp.devicePayload.device')}
                type="select"
              >
                <option value="" key="0" />
                {devices
                  ? devices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/device-payload" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DevicePayloadUpdate;
