import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './device-payload.reducer';

export const DevicePayloadDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const devicePayloadEntity = useAppSelector(state => state.devicePayload.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="devicePayloadDetailsHeading">
          <Translate contentKey="rainspotApp.devicePayload.detail.title">DevicePayload</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{devicePayloadEntity.id}</dd>
          <dt>
            <span id="attribute">
              <Translate contentKey="rainspotApp.devicePayload.attribute">Attribute</Translate>
            </span>
          </dt>
          <dd>{devicePayloadEntity.attribute}</dd>
          <dt>
            <span id="direction">
              <Translate contentKey="rainspotApp.devicePayload.direction">Direction</Translate>
            </span>
          </dt>
          <dd>{devicePayloadEntity.direction}</dd>
          <dt>
            <span id="value">
              <Translate contentKey="rainspotApp.devicePayload.value">Value</Translate>
            </span>
          </dt>
          <dd>{devicePayloadEntity.value}</dd>
          <dt>
            <span id="timeOfOrigin">
              <Translate contentKey="rainspotApp.devicePayload.timeOfOrigin">Time Of Origin</Translate>
            </span>
          </dt>
          <dd>
            {devicePayloadEntity.timeOfOrigin ? (
              <TextFormat value={devicePayloadEntity.timeOfOrigin} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="timeOfIngestion">
              <Translate contentKey="rainspotApp.devicePayload.timeOfIngestion">Time Of Ingestion</Translate>
            </span>
          </dt>
          <dd>
            {devicePayloadEntity.timeOfIngestion ? (
              <TextFormat value={devicePayloadEntity.timeOfIngestion} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="rainspotApp.devicePayload.device">Device</Translate>
          </dt>
          <dd>{devicePayloadEntity.device ? devicePayloadEntity.device.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/device-payload" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/device-payload/${devicePayloadEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DevicePayloadDetail;
