import { AUTHORITIES } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const DEFAULT_ORGANIZATION = 'RAINSPOT';
export const activationOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const mapFormValuesToInviteEntity = ({ values }) => {
  if (values.id !== undefined && typeof values.id !== 'number') {
    values.id = Number(values.id);
  }

  const entity = {
    ...values,
    authorities: [values?.authorities?.value],
    activated: false,
    organizationId: values?.organizationId?.value ?? null,
  };
  return entity;
};

export const mapFormValuesToEntity = ({ values, avatar, preview, applicationUserEntity }) => {
  if (values.id !== undefined && typeof values.id !== 'number') {
    values.id = Number(values.id);
  }

  const entity = {
    ...applicationUserEntity,
    ...values,
    state: values?.state?.value,
    city: values?.city?.value,
    authorities: [values?.authorities?.value],
    activated: values?.activated?.value,
    organizationId: values?.organizationId?.value ?? null,
    avatarExtension: avatar ? avatar?.type : null,
    avatar: avatar ? preview?.split(',')[1] : null,
  };
  delete entity.firstPassword;
  delete entity.secondPassword;
  delete entity.organization;
  delete entity.additionalField;
  return entity;
};

export const mapEntityToFormValues = ({ organizationOptions, authoritiesOption, applicationUserEntity, isNew, organizationId }) => {
  const userRole = authoritiesOption?.find(a => a?.name === applicationUserEntity?.internalUser?.authority?.[0]);

  return isNew
    ? {
        login: '',
        firstPassword: '',
        secondPassword: '',
        organizationId: organizationOptions?.find(o => o?.value?.toString() === organizationId?.toString()),
        authorities: null,
        activated: activationOptions?.find(a => !a?.value),
      }
    : {
        ...applicationUserEntity,
        email: applicationUserEntity?.internalUser?.email,
        login: applicationUserEntity?.internalUser?.login,
        firstName: applicationUserEntity?.internalUser?.firstName,
        lastName: applicationUserEntity?.internalUser?.lastName,
        city: null,
        state: applicationUserEntity?.state ? { value: applicationUserEntity?.state, label: applicationUserEntity?.state } : null,
        firstPassword: '',
        secondPassword: '',
        id: applicationUserEntity?.internalUser?.id,
        appUserId: applicationUserEntity?.id,
        authorities: userRole?.title ? { label: userRole?.title, value: userRole?.name } : null,
        organizationId: applicationUserEntity?.organization?.id
          ? organizationOptions?.find(o => o?.value?.toString() === applicationUserEntity?.organization?.id?.toString())
          : organizationOptions?.find(o => o?.label?.toString()?.toUpperCase() === DEFAULT_ORGANIZATION),
        activated: applicationUserEntity?.internalUser?.activated
          ? activationOptions?.find(a => a?.value === applicationUserEntity?.internalUser?.activated)
          : null,
      };
};

export const mockData = [
  {
    id: 2052,
    additionalField: null,
    address: '132 DURHAM RD',
    city: '815',
    state: 'Vermont',
    zipcode: '1212',
    phone: '(233)-283-2787',
    userFirstName: 'Administrator',
    userLastName: 'Admin',
    userEmail: 'admin@localhost.com',
    organizationId: 18,
    organizationName: 'Rainspot',
    organizationCity: '230',
    organizationState: 'Alabama',
    activated: 'Deactivated',
    imageUrl: null,
    account: 'Platform Admin',
  },
  {
    id: 585,
    address: '123 Main St',
    city: '1',
    state: 'Alaska',
    zipcode: '10001',
    phone: '(123)-456-7890',
    createdDate: '2024-04-15T14:48:32.363217Z',
    userId: 576,
    userFirstName: 'John',
    userLastName: 'Doe',
    userEmail: '4vb9uq@gmail.com',
    organizationId: 18,
    organizationName: 'Rainspot',
    organizationCity: '230',
    organizationState: 'Alabama',
    activated: 'Activated',
    imageUrl: null,
    account: 'Platform Admin',
  },
];

export const mockDataEdit = {
  id: 2956,
  additionalField: null,
  address: 'DevTek Inc',
  city: '1',
  state: 'State 1',
  zipcode: 1234,
  phone: '(263)-726-3726',
  internalUser: {
    id: 2906,
    login: 'user24',
    firstName: 'user',
    lastName: '24',
    email: 'user24@gmail.com',
    imageUrl: null,
    activated: true,
    authority: ['ROLE_UTILITY_ADMIN'],
  },
  organization: {
    id: 1151,
  },
  account: null,
};

export const AUTHORITY_OPTIONS = [
  {
    name: 'ROLE_ADMIN',
    title: 'Platform Admin',
  },
  {
    name: 'ROLE_USER',
    title: 'Platform User',
  },
  {
    name: 'ROLE_UTILITY_ADMIN',
    title: 'Organization Admin',
  },
  {
    name: 'ROLE_UTILITY_USER',
    title: 'Organization User',
  },
  {
    name: 'DEVICE_OWNER',
    title: 'Device Owner',
  },
];

export const useDecisionBasedOnRole = ({ authorities, organizationId }) => {
  const isOrganizationRole = useAppSelector(state =>
    hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER]),
  );
  const isOrganizationAdmin = useAppSelector(state =>
    hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.UTILITY_ADMIN]),
  );
  const isOrganizationUser = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.UTILITY_USER]));
  const isOrganizationDisabled =
    !['ROLE_UTILITY_ADMIN', 'ROLE_UTILITY_USER'].includes(authorities?.value) || !!organizationId || isOrganizationRole;

  return {
    isOrganizationUser,
    isOrganizationAdmin,
    isOrganizationDisabled,
    isOrganizationRole,
  };
};

export const USER_TABLE_QUERY_KEYS = {
  userEmail: 'userEmail.contains',
  userFirstName: 'userFirstName.contains',
  userLastName: 'userLastName.contains',
  account: 'account.contains',
  activated: 'activated.contains',
  createdDateStart: 'createdDate.greaterThanOrEqual',
  createdDateEnd: 'createdDate.lessThanOrEqual',
};
