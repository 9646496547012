import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IState, defaultValue } from 'app/shared/model/state.model';
import { sortBy } from 'lodash';
import _ from 'lodash';

const initialState: EntityState<IState> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  menuOptions: [],
};

const apiUrl = 'api/cities/counties';

// Actions

export const getEntities = createAsyncThunk('county/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IState[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'county/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IState>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const countySlice = createEntitySlice({
  name: 'counties',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      });
  },
});

export const { reset } = countySlice.actions;

// Reducer
export default countySlice.reducer;
