import React from 'react';

function RemoveDesktop() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g id="Remove Desktop">
        <path
          id="Vector"
          d="M22 8H12C9.79086 8 8 9.79086 8 12V28C8 30.2091 9.79086 32 12 32H36C38.2091 32 40 30.2091 40 28V26M41.7573 7.75732L36.7573 12.7573M36.7573 12.7573L31.7573 17.7573M36.7573 12.7573L31.7573 7.75732M36.7573 12.7573L41.7573 17.7573M24 32V40M16 40H32"
          stroke="#F56565"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default RemoveDesktop;
