import dayjs from 'dayjs';
import { IDevice } from 'app/shared/model/device.model';
import { MetricType } from 'app/shared/model/enumerations/metric-type.model';

export interface IMetric {
  id?: number;
  attribute?: string;
  type1?: keyof typeof MetricType | null;
  value?: keyof typeof MetricType;
  dateCreated?: dayjs.Dayjs | null;
  dateUpdated?: dayjs.Dayjs | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  deviceReportedTime?: dayjs.Dayjs | null;
  device?: IDevice | null;
}

export const defaultValue: Readonly<IMetric> = {};
