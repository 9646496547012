import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Location from './location';
import Comment from './comment';
import ApplicationUser from './application-user';
import City from './city';
import Neighborhood from './neighborhood';
import SerialNumberRange from './serial-number-range';
import Organization from './organization';
import Device from './device';
import DevicePayload from './device-payload';
import Metric from './metric';
import DeviceReportedMetric from './device-reported-metric';
import ComputedMetric from './computed-metric';
import Mesh from './mesh';
import Maps from './maps';
import Hive from './hive';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Inventory from './inventory';
import Messages from './messages';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route
          path="comment/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.SUPERADMIN]}>
              <Comment />
            </PrivateRoute>
          }
        />
        <Route
          path="application-user/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN]}>
              <ApplicationUser />
            </PrivateRoute>
          }
        />
        <Route
          path="inventory/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN]}>
              <Inventory />
            </PrivateRoute>
          }
        />
        <Route
          path="city/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.SUPERADMIN]}>
              <City />
            </PrivateRoute>
          }
        />
        <Route
          path="neighborhood/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN]}>
              <Neighborhood />
            </PrivateRoute>
          }
        />
        <Route
          path="organization/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN]}>
              <Organization />
            </PrivateRoute>
          }
        />
        <Route
          path="metric/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.SUPERADMIN]}>
              <Metric />
            </PrivateRoute>
          }
        />
        <Route
          path="device-reported-metric/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN]}>
              <DeviceReportedMetric />
            </PrivateRoute>
          }
        />
        <Route
          path="computed-metric/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.SUPERADMIN]}>
              <ComputedMetric />
            </PrivateRoute>
          }
        />
        {/* <Route path="mesh/*" element={<Mesh />} /> */}
        <Route
          path="maps/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN]}>
              <Maps />
            </PrivateRoute>
          }
        />
        <Route
          path="messages/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN]}>
              <Messages />
            </PrivateRoute>
          }
        />
        <Route
          path="hive/*"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER]}
            >
              <Hive />
            </PrivateRoute>
          }
        />
        <Route
          path="device/*"
          element={
            <PrivateRoute
              hasAnyAuthorities={[
                AUTHORITIES.ADMIN,
                AUTHORITIES.SUPERADMIN,
                AUTHORITIES.UTILITY_ADMIN,
                AUTHORITIES.DEVICE_OWNER,
                AUTHORITIES.UTILITY_USER,
              ]}
            >
              <Device />
            </PrivateRoute>
          }
        />
        <Route
          path="device-payload/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN]}>
              <DevicePayload />
            </PrivateRoute>
          }
        />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
