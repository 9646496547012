import React from 'react';

function EyeShow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none">
      <g id="eye-password-show-svgrepo-com 1">
        <path
          id="Vector"
          d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
          stroke="#AFAFAF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
          stroke="#AFAFAF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
          stroke="#AFAFAF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default EyeShow;
