import React from 'react';

function AddLinkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="Link 3">
        <path
          id="Vector"
          d="M7.11091 8.88892L6.72039 8.4984C6.19969 7.9777 6.19969 7.13348 6.72039 6.61278L9.72365 3.60952C10.2444 3.08882 11.0886 3.08882 11.6093 3.60952L12.3903 4.39056C12.911 4.91126 12.911 5.75548 12.3903 6.27618L11.3331 7.33337M8.88868 7.11116L9.2792 7.50168C9.7999 8.02238 9.7999 8.8666 9.2792 9.3873L6.27594 12.3906C5.75524 12.9113 4.91102 12.9113 4.39032 12.3906L3.60927 11.6095C3.08858 11.0888 3.08857 10.2446 3.60927 9.7239L4.66646 8.66671"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default AddLinkIcon;
