import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IDeviceLocation, defaultValue } from 'app/shared/model/device-location';

const initialState: EntityState<IDeviceLocation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/device-locations';

// Actions

export const getEntity = createAsyncThunk(
  'device-location/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/device/${id}`;
    return axios.get<IDeviceLocation>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'device-location/create_entity',
  async (entity: IDeviceLocation, thunkAPI) => {
    const result = await axios.post<IDeviceLocation>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity.deviceId));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'device-location/update_entity',
  async (entity: IDeviceLocation, thunkAPI) => {
    const result = await axios.put<IDeviceLocation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity.deviceId));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const DeviceLocationSlice = createEntitySlice({
  name: 'devicelocation',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = DeviceLocationSlice.actions;

// Reducer
export default DeviceLocationSlice.reducer;
