import { useAppDispatch } from 'app/config/store';
import AddUserIcon from 'app/shared/icons/add-icon';
import { convertDateTimeFromServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { getFilterByState, reactStrapStyles, validateTelePhoneNumber } from 'app/shared/util/form-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { MdOutlineCancel } from 'react-icons/md';
import { Translate, ValidatedField, isEmail, translate } from 'react-jhipster';
import { PatternFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button, FormFeedback, Input, InputGroup, Label } from 'reactstrap';
import { getEntities as getCityEntitiesByState } from '../city/city.reducer';

export const OrganizationForm = ({ organizationEntity, cities, states, loading, isNew, saveEntity, updating }) => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    reset: resetForm,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm();

  const {
    fields: contactInfo,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'contactInfo',
  });

  const [status, setStatus] = useState([
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'InActive' },
  ]);

  const watchContacts = watch('contactInfo');
  const defaultValues = useMemo(() => {
    return isNew
      ? {
          dateCreated: displayDefaultDateTime(),
          dateUpdated: displayDefaultDateTime(),
          contactInfo: [{ name: '', phoneNo: '', email: '' }],
        }
      : {
          ...organizationEntity,
          dateCreated: convertDateTimeFromServer(organizationEntity.dateCreated),
          dateUpdated: convertDateTimeFromServer(organizationEntity.dateUpdated),
          state: { value: organizationEntity?.state, label: organizationEntity?.state },
          city: null,
          status: status.find(st => st.value === organizationEntity.status),
        };
  }, [organizationEntity]);

  useEffect(() => {
    if (cities?.length > 0) {
      const city = cities?.find(it => it.value?.toString() === organizationEntity?.city?.toString());
      setValue('city', city);
    }
  }, [cities, organizationEntity]);

  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [resetForm, defaultValues]);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
          <div className="form-body">
            <div className="d-flex">
              <ValidatedField
                label={translate('rainspotApp.organization.name')}
                id="organization-name"
                data-testid="organization-name"
                name="name"
                register={register}
                error={errors.name as FieldError}
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <div style={{ display: 'flex' }} className="flex-column">
                <Label for="status">{'Status'}*</Label>
                <InputGroup className="mb-3" error={errors?.state} data-testid={'status'}>
                  <Controller
                    control={control}
                    name="status"
                    rules={{ required: 'Status is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <ReactSelect
                            menuPlacement="auto"
                            classNamePrefix="status"
                            options={status}
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(file: any) => {
                              field.onChange(file);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex">
              <ValidatedField
                label="Address*"
                id="organization-street"
                data-testid="organization-street"
                name="streetAddress"
                register={register}
                error={errors.name as FieldError}
                data-cy="organization-street"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <div style={{ display: 'flex' }} className="flex-column selectable">
                <Label for="state">{'State'}*</Label>
                <InputGroup className="mb-3" error={errors?.state} data-testid={'state'}>
                  <Controller
                    control={control}
                    name="state"
                    rules={{ required: 'State is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <ReactSelect
                            classNamePrefix="state"
                            menuPlacement="auto"
                            options={states}
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(option: any) => {
                              field.onChange(option);
                              const filterUrl = getFilterByState(option.value);
                              dispatch(getCityEntitiesByState({ filterUrl }));
                              setValue('city', null);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ display: 'flex' }} className="flex-column">
                <Label for="city">{'City'}*</Label>
                <InputGroup className="mb-3" error={errors?.city} data-testid={'city'}>
                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: 'City is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <ReactSelect
                            menuPlacement="auto"
                            classNamePrefix="city"
                            options={cities}
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(file: any) => {
                              field.onChange(file);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>
              <ValidatedField
                register={register}
                type="number"
                name="zipCode"
                data-testid="zipCode"
                error={errors?.zipCode as FieldError}
                data-cy="organization-zipcode"
                label={translate('rainspotApp.organization.zipCode')}
                validate={{
                  required: { value: true, message: translate('register.messages.validate.zipcode.required') },
                  minLength: { value: 1, message: translate('register.messages.validate.zipcode.minlength') },
                  maxLength: { value: 16, message: translate('register.messages.validate.zipcode.maxlength') },
                }}
              />
            </div>
            <div className="d-flex">
              <div className="heading-section">
                Contact Details
                <hr />
              </div>
            </div>
            {Array.isArray(watchContacts) &&
              watchContacts?.map((contact, index) => {
                return (
                  <div key={index} className="d-flex">
                    <ValidatedField
                      register={register}
                      type="text"
                      name={`contactInfo.${index}.title`}
                      data-testid={`contactInfo.${index}.title`}
                      error={errors.contactInfo?.[index]?.title as FieldError}
                      data-cy="contact-title"
                      label={'Title*'}
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                    <ValidatedField
                      register={register}
                      type="text"
                      name={`contactInfo.${index}.name`}
                      data-testid={`contactInfo.${index}.name`}
                      error={errors.contactInfo?.[index]?.name as FieldError}
                      data-cy="contact-name"
                      label={'Name*'}
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                    <div style={{ display: 'flex' }} className="flex-column selectable">
                      <Label for="phone">{'Phone'}*</Label>
                      <Controller
                        control={control}
                        name={`contactInfo.${index}.phoneNo`}
                        rules={{
                          validate(number: string | null) {
                            if (!number) return 'Phone Number is required';
                            if (number?.length < 10) {
                              return 'Valid Phone Number Is required';
                            }
                            return validateTelePhoneNumber(number) || 'Invalid phone number';
                          },
                        }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <PatternFormat
                                customInput={Input}
                                value={field.value}
                                className={`${fieldState?.error ? 'is-invalid' : ''}`}
                                data-testid={`contactInfo.${index}.phoneNo`}
                                onChange={e => field.onChange(e)}
                                format="(###)-###-####"
                                mask="_"
                                placeholder="(___)-___-____"
                              />
                              <FormFeedback>{fieldState?.error && 'Valid Phone Number Is Required'}</FormFeedback>
                            </>
                          );
                        }}
                      />
                    </div>
                    <ValidatedField
                      register={register}
                      type="text"
                      name={`contactInfo.${index}.email`}
                      data-testid={`contactInfo.${index}.email`}
                      error={errors.contactInfo?.[index]?.email as FieldError}
                      data-cy="contact-email"
                      label={'Email*'}
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                        validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                      }}
                    />
                    {index > 0 && (
                      <div
                        className="d-flex justify-content-center"
                        data-testid="remove-contact"
                        onClick={() => {
                          remove(index);
                        }}
                        style={{ alignItems: 'center', cursor: 'pointer' }}
                      >
                        <MdOutlineCancel className="primary-link" size="1.4em" />
                      </div>
                    )}
                  </div>
                );
              })}

            <div className="d-flex">
              <Button
                data-cy="add-contact"
                data-testid="add-contact"
                onClick={() => {
                  append({ title: '', name: '', phoneNo: '', email: '' });
                }}
                color="add-contact"
              >
                <AddUserIcon />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="rainspotApp.organization.addContact">add contact</Translate>
                </span>
              </Button>
            </div>
          </div>
          <hr />
          <div className="justify-content-end d-flex form-footer">
            <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/organization" replace color="info">
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.cancel">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button
              color="primary"
              id="save-entity"
              data-cy="entityCreateSaveButton"
              data-testid="entityCreateSaveButton"
              type="submit"
              disabled={updating}
            >
              <Translate contentKey="entity.action.save">Save</Translate>
            </Button>
          </div>
        </form>
      )}
    </>
  );
};
