import React from 'react';
import { AdministrationMenu, InternalEntities, EntitiesMenu } from 'app/entities/menu';

export const SideBar = ({ authorities }) => {
  return (
    <>
      <EntitiesMenu authorities={authorities} />
      <AdministrationMenu authorities={authorities} />
      <InternalEntities authorities={authorities} />
    </>
  );
};
