export const AUTHORITIES = {
  SUPERADMIN: 'SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  UTILITY_ADMIN: 'ROLE_UTILITY_ADMIN',
  UTILITY_USER: 'ROLE_UTILITY_USER',
  DEVICE_OWNER: 'DEVICE_OWNER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'MM/DD/YY HH:mm';
export const APP_DATE_FORMAT_DEFAULT = 'MM/DD/YY';
export const APP_DATE_FORMAT_DATE_PICKER = 'YYYY-MM-DD';
export const APP_DATE_FORMAT_MAP = 'D-M-YYYY';
export const APP_TIMESTAMP_FORMAT = 'MM/DD/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
