import DeviceSetting from 'app/shared/icons/desktop-setting';
import React from 'react';
import { Card } from 'reactstrap';

export const SummaryCards = ({ cards }) => {
  return (
    <div className="d-flex flex-row gap-3">
      {cards.map((card, index) => (
        <Card key={index} data-testid={'card-' + 0} className="jh-card summary-card">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="card-title">{card?.title}</span>
              <span className="card-value" data-testid={'value-' + index}>
                {card?.value}
              </span>
            </div>
            {card?.icon}
          </div>
        </Card>
      ))}
    </div>
  );
};
