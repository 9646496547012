import { IDevice } from 'app/shared/model/device.model';
import { IMesh } from 'app/shared/model/mesh.model';
import { IComment } from 'app/shared/model/comment.model';
import { ICity } from 'app/shared/model/city.model';

export interface INeighborhood {
  id?: number;
  name?: string;
  countId?: string | null;
  stateId?: string | null;
  regionId?: string | null;
  devices?: IDevice[] | null;
  meshes?: IMesh[] | null;
  comments?: IComment[] | null;
  city?: ICity | null;
}

export const defaultValue: Readonly<INeighborhood> = {};
