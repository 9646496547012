import CrossIcon from 'app/shared/icons/cross-icon';
import React, { useState } from 'react';

export const FilterInput = ({ column }) => {
  const [inputVal, setInputVal] = useState('');
  return (
    <div style={{ position: 'relative' }}>
      <input
        className="filter-input"
        onClick={e => {
          e.stopPropagation();
        }}
        value={inputVal}
        onChange={e => {
          column.setFilterValue(e.target.value);
          setInputVal(e.target.value);
        }}
      ></input>
      {inputVal && (
        <span
          onClick={e => {
            e.stopPropagation();
            column.setFilterValue('');
            setInputVal('');
          }}
        >
          <CrossIcon className="removeIcon" />
        </span>
      )}
    </div>
  );
};

export const FilterDate = ({ column }) => {
  const [inputVal, setInputVal] = useState('');
  return (
    <div style={{ position: 'relative' }}>
      <input
        className="filter-date"
        onClick={e => {
          e.stopPropagation();
        }}
        type="date"
        value={inputVal}
        onChange={e => {
          column.setFilterValue(e.target.value);
          setInputVal(e.target.value);
        }}
      ></input>
      {inputVal && (
        <span
          onClick={e => {
            e.stopPropagation();
            column.setFilterValue('');
            setInputVal('');
          }}
        >
          <CrossIcon className="removeIcon" />
        </span>
      )}
    </div>
  );
};
