import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICity, defaultValue } from 'app/shared/model/city.model';
import { filter, sortBy } from 'lodash';
import _ from 'lodash';

const initialState: EntityState<ICity> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  menuOptions: [],
};

const apiUrl = 'api/cities';

// Actions

export const getEntities = createAsyncThunk('city/fetch_entity_list', async ({ page, size, sort, filterUrl }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${filterUrl}${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICity[]>(requestUrl);
});

export const getCountyEntities = createAsyncThunk('city/fetch_county_entity_list', async (state: string) => {
  const requestUrl = `${apiUrl}?${state ? `state.equals=${state}&size=10000000&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICity[]>(requestUrl);
});

export const getOwnerCountyEntities = createAsyncThunk('city/fetch_owner_county_entity_list', async (state: string) => {
  const requestUrl = `${apiUrl}?${state ? `state.equals=${state}&size=10000000&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICity[]>(requestUrl);
});

export const getOwnerCityEntities = createAsyncThunk(
  'city/fetch_owner_city_entity_list',
  async ({ county, state }: { county: string; state: string }) => {
    const requestUrl = `${apiUrl}?${state ? `state.equals=${state}&` : ''}${
      county ? `county.equals=${county}&size=10000000&` : ''
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<ICity[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'city/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICity>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);
export const createEntity = createAsyncThunk(
  'city/create_entity',
  async (entity: ICity, thunkAPI) => {
    const result = await axios.post<ICity>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'city/update_entity',
  async (entity: ICity, thunkAPI) => {
    const result = await axios.put<ICity>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'city/partial_update_entity',
  async (entity: ICity, thunkAPI) => {
    const result = await axios.patch<ICity>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'city/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICity>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const CitySlice = createEntitySlice({
  name: 'city',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getCountyEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getOwnerCountyEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getOwnerCityEntities.fulfilled, (state, action) => {
        state.loading = false;
        (state as any).ownerCityEntities = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getCountyEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          countyMenuOptions: _.uniqBy(sortBy(data, ['name'])?.map(d => ({ label: d?.county, value: d.county })), 'label'),
          countyLoading: false,
          countyEntities: data,
          countyTotalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOwnerCountyEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          ownerCountyMenuOptions: _.uniqBy(sortBy(data, ['name'])?.map(d => ({ label: d?.county, value: d.county })), 'label'),
          countyLoading: false,
          countyEntities: data,
          countyTotalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          menuOptions: _.uniqBy(sortBy(data, ['name'])?.map(d => ({ label: d?.name, value: d.id })), 'label'),
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOwnerCityEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          ownerCityMenuOptions: _.uniqBy(sortBy(data, ['name'])?.map(d => ({ label: d?.name, value: d.id })), 'label'),
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CitySlice.actions;

// Reducer
export default CitySlice.reducer;
