import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { getEntity } from '../device.reducer';

const DeviceMessages = ({ entity }) => {
  const { id: deviceId } = useParams<'id'>();
  const dispatch = useAppDispatch();

  const authToken = JSON.parse(localStorage.getItem('jhi-authenticationToken') || sessionStorage.getItem('jhi-authenticationToken'));

  const account = useAppSelector(state => state.authentication.account);
  const isAccountLoading = useAppSelector(state => state.authentication.loading);

  const deviceEntity = useAppSelector(state => state.device.entity);
  const isDeviceLoading = useAppSelector(state => state.device.loading);

  let platformParam = 'platform=1';
  if (entity === 'all') {
    platformParam += '&entity=all';
  }

  let iframeUrl = REACT_APP_CRM_URL + '?' + platformParam;

  const iframe = useRef<HTMLIFrameElement>(null);

  const sendMessage = () => {
    iframe?.current?.contentWindow?.postMessage(
      { token: authToken, user: account, payload: { deviceId, entity, deviceSerialNumber: deviceEntity?.serialNumber } },
      '*',
    );
  };

  useEffect(() => {
    window.addEventListener('message', (event: any) => {
      if (event.data.messagesLoaded && deviceEntity?.serialNumber) {
        sendMessage();
      }
    });
    dispatch(getSession());
    dispatch(getEntity(deviceId));
  }, [JSON.stringify(deviceEntity)]);

  return (
    <>
      {isAccountLoading || isDeviceLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="device-message-tab">
          <iframe id="messagesPortalIframe" ref={iframe} src={iframeUrl} width="100%" height="100%" title="Messages"></iframe>
        </div>
      )}
    </>
  );
};

export default DeviceMessages;
