import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { Maps } from './maps';

const MapsUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Maps />} />
  </ErrorBoundaryRoutes>
);

export default MapsUserRoutes;
