import React from 'react';

function EyeHide() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
      <g id="hide-svgrepo-com 1" clipPath="url(#clip0_607_8107)">
        <g id="Shopicon">
          <path
            id="Vector"
            d="M7.47312 20.7594L4.4825 23.75L6.25 25.5175L9.56563 22.2019C11.1669 23.12 12.9925 23.75 15 23.75C22.5 23.75 27.5 15 27.5 15C27.5 15 25.6537 11.775 22.5269 9.24061L25.5175 6.24998L23.75 4.48248L20.4344 7.79811C18.8331 6.87998 17.0075 6.24998 15 6.24998C7.5 6.24998 2.5 15 2.5 15C2.5 15 4.34625 18.225 7.47312 20.7594ZM20.7525 11.015C22.42 12.3219 23.7194 13.9137 24.5 14.9956C22.955 17.1225 19.39 21.25 15 21.25C13.72 21.25 12.5169 20.8981 11.4112 20.3562L12.4637 19.3037C13.2081 19.7431 14.0731 20 15 20C17.7612 20 20 17.7612 20 15C20 14.0731 19.7431 13.2081 19.3037 12.4637L20.7525 11.015ZM12.5931 15.6394C12.5388 15.4337 12.5 15.2225 12.5 15C12.5 13.6212 13.6213 12.5 15 12.5C15.2225 12.5 15.4338 12.5387 15.6394 12.5931L12.5931 15.6394ZM17.4069 14.3606C17.4612 14.5662 17.5 14.7775 17.5 15C17.5 16.3787 16.3787 17.5 15 17.5C14.7775 17.5 14.5662 17.4612 14.3606 17.4069L17.4069 14.3606ZM15 8.74998C16.28 8.74998 17.4831 9.10186 18.5888 9.64373L17.5363 10.6962C16.7919 10.2569 15.9269 9.99998 15 9.99998C12.2388 9.99998 10 12.2387 10 15C10 15.9269 10.2569 16.7919 10.6962 17.5362L9.2475 18.985C7.58 17.6781 6.28062 16.0862 5.49937 15.0044C7.045 12.8775 10.61 8.74998 15 8.74998Z"
            fill="#AFAFAF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_607_8107">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeHide;
