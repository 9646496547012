import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getCityEntitiesByState } from 'app/entities/city/city.reducer';
import { getEntities as getStates } from 'app/entities/city/state.reducer';
import { ConfirmationBox } from 'app/modules/common/confirmation-box';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { MAX_SIZE, getFilterByState, reactStrapStyles, validateTelePhoneNumber } from 'app/shared/util/form-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Translate, ValidatedField, isEmail, translate } from 'react-jhipster';
import { PatternFormat } from 'react-number-format';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button, Col, FormFeedback, Input, InputGroup, Label, Row } from 'reactstrap';
import { getInvitedUser, handleRegister, reset } from './register.reducer';
import CheckCircle from 'app/shared/icons/check-circle';

export const RegisterPage = () => {
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const states = useAppSelector(state => state.states.menuOptions);
  const cities = useAppSelector(state => state.city.menuOptions);
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  useEffect(() => {
    dispatch(reset());
    dispatch(getStates({}));
  }, []);
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    control,
    reset: resetForm,
  } = useForm();
  const registrationSuccess = useAppSelector(state => state.register.registrationSuccess);
  const currentLocale = useAppSelector(state => state.register.currentLocale);
  const invitedUser = useAppSelector(state => state.register.invitedUser);
  const [loadModal, setLoadModal] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      firstName: invitedUser?.firstName ?? '',
      lastName: invitedUser?.lastName ?? '',
      email: invitedUser?.email ?? '',
      organizationId: invitedUser?.organizationId ?? null,
    };
  }, [invitedUser]);

  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [resetForm, defaultValues]);

  const onSubmit = values => {
    dispatch(
      handleRegister({
        ...values,
        login: values.username,
        email: values.email,
        password: values.firstPassword,
        langKey: currentLocale,
        city: values?.city?.value,
        state: values?.state?.value,
        key,
      }),
    );
  };

  useEffect(() => {
    if (key) {
      dispatch(getInvitedUser({ key }));
    }
  }, [key]);

  useEffect(() => {
    if (registrationSuccess) {
      setLoadModal(true);
    }
  }, [registrationSuccess]);

  /* useEffect(() => {
    if (watchAddressCheck) {
      setValue('installationAddress', formValues.address);
      setValue('installationCity', formValues.city);
      setValue('installationState', formValues.state);
      setValue('installationZipCode', formValues.zipcode);
    } else {
      setValue('installationAddress', '');
      setValue('installationCity', '');
      setValue('installationState', '');
      setValue('installationZipCode', '');
    }
  }, [watchAddressCheck]);*/

  const updatePassword = event => setPassword(event.target.value);

  return (
    <>
      {!loadModal && (
        <div className="register-form-card">
          <div className="register-form">
            <Row className="justify-content-center">
              <Col md="12">
                <h1 id="register-title" data-cy="registerTitle">
                  <Translate contentKey="register.title">Registration</Translate>
                </h1>
              </Col>
            </Row>
            <form onSubmit={vals => void handleSubmit(onSubmit)(vals)}>
              <Row className="justify-content-center">
                <Col md="6">
                  <ValidatedField
                    name="email"
                    data-cy="email"
                    register={register}
                    label={translate('global.form.email.label')}
                    error={errors?.email as FieldError}
                    disabled={!!key}
                    type="email"
                    validate={{
                      required: { value: true, message: translate('global.messages.validate.email.required') },
                      minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                      maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                      validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                    }}
                  />
                </Col>
                <Col md="6" />
              </Row>
              <Row className="justify-content-center">
                <Col md="6">
                  <ValidatedField
                    name="firstPassword"
                    data-cy="firstPassword"
                    register={register}
                    label={translate('global.form.newpassword.label')}
                    type="password"
                    error={errors?.firstPassword as FieldError}
                    onChange={updatePassword}
                    validate={{
                      required: { value: true, message: translate('global.messages.validate.newpassword.required') },
                      minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
                      maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
                    }}
                  />
                  <PasswordStrengthBar password={password} />
                  <PasswordText />
                </Col>
                <Col md="6">
                  <ValidatedField
                    name="secondPassword"
                    data-cy="secondPassword"
                    register={register}
                    label={translate('global.form.confirmpassword.label')}
                    type="password"
                    error={errors?.secondPassword as FieldError}
                    validate={{
                      required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
                      minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
                      maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
                      validate: v => v === password || translate('global.messages.error.dontmatch'),
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="6">
                  <ValidatedField
                    register={register}
                    type="text"
                    name="firstName"
                    data-cy="firstName"
                    error={errors?.firstName as FieldError}
                    label={translate('global.form.firstName.label')}
                    validate={{
                      required: { value: true, message: translate('register.messages.validate.firstName.required') },
                      minLength: { value: 1, message: translate('register.messages.validate.firstName.minlength') },
                      maxLength: { value: 50, message: translate('register.messages.validate.firstName.maxlength') },
                    }}
                  />
                </Col>
                <Col md="6">
                  <ValidatedField
                    register={register}
                    type="text"
                    name="lastName"
                    data-cy="lastName"
                    error={errors?.lastName as FieldError}
                    label={translate('global.form.lastName.label')}
                    validate={{
                      required: { value: true, message: translate('register.messages.validate.lastName.required') },
                      minLength: { value: 1, message: translate('register.messages.validate.lastName.minlength') },
                      maxLength: { value: 50, message: translate('register.messages.validate.lastName.maxlength') },
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="6">
                  <Label for="phone">{'Phone'}*</Label>
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      validate(number: string | null) {
                        if (!number) return 'Phone Number is required';
                        if (number?.length < 10) {
                          return 'Valid Phone Number Is required';
                        }
                        return validateTelePhoneNumber(number) || 'Invalid phone number';
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <PatternFormat
                            data-cy="phone"
                            customInput={Input}
                            className={`${fieldState?.error ? 'is-invalid' : ''}`}
                            value={field.value}
                            onChange={e => field.onChange(e)}
                            format="(###)-###-####"
                            mask="_"
                            placeholder="(___)-___-____"
                          />
                          <FormFeedback>{fieldState?.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </Col>
                <Col md="6">
                  <ValidatedField
                    register={register}
                    type="text"
                    data-cy="address"
                    name="address"
                    error={errors?.address as FieldError}
                    label={translate('global.form.address.label')}
                    validate={{
                      required: { value: true, message: translate('register.messages.validate.address.required') },
                      minLength: { value: 1, message: translate('register.messages.validate.address.minlength') },
                      maxLength: { value: 100, message: translate('register.messages.validate.address.maxlength') },
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="6">
                  <Label for="state">{'State'}*</Label>
                  <InputGroup className="mb-3" error={errors?.state} data-cy="state">
                    <Controller
                      control={control}
                      name="state"
                      rules={{ required: 'State is required' }}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <ReactSelect
                              classNamePrefix="state"
                              menuPlacement="auto"
                              options={states}
                              styles={reactStrapStyles({ width: '100%', error: !!fieldState?.error?.message })}
                              value={field.value}
                              onChange={(option: any) => {
                                field.onChange(option);
                                const filterUrl = getFilterByState(option?.value);
                                dispatch(getCityEntitiesByState({ filterUrl }));
                                setValue('city', null);
                              }}
                            />

                            <FormFeedback>{fieldState.error?.message}</FormFeedback>
                          </>
                        );
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <Label for="city">{'City'}*</Label>
                  <InputGroup className="mb-3" error={errors?.state} data-cy="city">
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: 'City is required' }}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <ReactSelect
                              classNamePrefix="city"
                              menuPlacement="auto"
                              options={cities}
                              styles={reactStrapStyles({ width: '100%', error: !!fieldState?.error?.message })}
                              value={field.value}
                              onChange={(file: any) => {
                                field.onChange(file);
                              }}
                            />

                            <FormFeedback>{fieldState.error?.message}</FormFeedback>
                          </>
                        );
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <ValidatedField
                    register={register}
                    type="number"
                    name="zipcode"
                    data-cy="zipcode"
                    error={errors?.zipcode as FieldError}
                    label={translate('global.form.zipcode.label')}
                    validate={{
                      required: { value: true, message: translate('register.messages.validate.zipcode.required') },
                      minLength: { value: 1, message: translate('register.messages.validate.zipcode.minlength') },
                      maxLength: { value: 16, message: translate('register.messages.validate.zipcode.maxlength') },
                    }}
                  />
                </Col>
              </Row>
              <hr />
              <div className="d-flex justify-content-center">
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/" replace color="info">
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.cancel">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
      {loadModal && (
        <ConfirmationBox
          icon={<CheckCircle />}
          message={!key ? 'You have successfully registered. Check email for activation link.' : 'You have successfully registered.'}
          buttonText={'Contine'}
          buttonLink={'/login'}
        />
      )}
    </>
  );
};

export default RegisterPage;

export const PasswordText = ({ style }: { style?: any }) => {
  return (
    <div className="password-note" style={{ ...style }}>
      A strong password should be 8 or more characters and contain at least 1 number and 1 special character.
    </div>
  );
};
