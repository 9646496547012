import { UseFormRegisterReturn } from 'react-hook-form';

export const registerRs = (fieldName: string, options = {}, register) => {
  const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
  const ref = registeredField.ref;
  delete registeredField.ref;
  return { ...registeredField, innerRef: ref };
};

type StyleProps = {
  width?: string;
  error?: boolean;
};
export const reactStrapStyles = ({ width, error }: StyleProps) => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: '6px',
      borderColor: error ? 'red' : state.isFocused ? '#86b7fe' : '#e2e8f0',
      boxShadow: 'none',
    }),
    container: (baseStyles, state) => ({
      ...baseStyles,
      width: width || '245px',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginLeft: '10px',
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      marginLeft: '10px',
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      padding: '0px',
      marginLeft: '5px',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isSelected ? '#08625f' : 'white',
      ':hover': {
        backgroundColor: '#285e61',
        color: 'white',
        opacity: 0.5,
      },
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      display: 'none',
    }),
  };
};

export const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validateTelePhoneNumber = (number: string): boolean => {
  return number ? number.match(/\d/g)?.length === 10 : false;
};

export const convertImageUrltoDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export const dataURLtoFile = (dataUrl, fileName) => {
  const arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

export const isValidAndNonEmpty = item => {
  return item !== null && item !== undefined && item?.toString()?.trim() !== '';
};

export const MAX_SIZE = 10000000;

export const getFilterByState = state => {
  return `state.equals=${state}&size=${MAX_SIZE}&`;
};
export const getFilterByStateAndCounty = (state, county) => {
  return `state.equals=${state}&county.equals=${county}&size=${MAX_SIZE}&`;
};
