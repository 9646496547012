import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { Button, Alert, Col, Row, Card } from 'reactstrap';
import { toast } from 'react-toastify';

import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { BrandIcon, BrandIconLarge } from 'app/shared/layout/header/header-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [],
  );

  const handleValidSubmit = ({ email }) => {
    dispatch(handlePasswordResetInit(email));
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  return (
    <Card className="login-form-card">
      <div id="login-form">
        <div id="login-title" className="login-logo" data-cy="loginTitle">
          <BrandIconLarge imgH={'155px'} />
        </div>
        <div className="mt-10">&nbsp;</div>
        <Row className="justify-content-center">
          <Col md="12">
            <Link to="/login" data-cy="backToLogin" className="primary-link">
              {'<'}
              &nbsp;
              <Translate contentKey="reset.request.back">Back To Login?</Translate>
            </Link>
            <div className="mt-10">&nbsp;</div>
            <h3>
              <Translate contentKey="reset.request.title">Reset your password</Translate>
            </h3>
            <p>
              <Translate contentKey="reset.request.note">Note</Translate>
            </p>
            <ValidatedForm onSubmit={handleValidSubmit}>
              <ValidatedField
                name="email"
                label={translate('global.form.email.label')}
                type="email"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.email.required') },
                  minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                  maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                  validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                }}
                data-cy="emailResetPassword"
              />
              <div className="mt-10">&nbsp;</div>
              <div className={'w-100 pl-40 pr-40 sign-in'}>
                <Button color="primary" className="w-100" type="submit" data-cy="submit">
                  <Translate contentKey="reset.request.form.submit">Reset password</Translate>
                </Button>
              </div>
            </ValidatedForm>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default PasswordResetInit;
