import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import { useState, useMemo, useEffect } from 'react';
import { IPaginationBaseState } from 'react-jhipster';

type UseColumnFiltersQueryStringProps = {
  queryStringAPIFilterKeys: { [key: string]: string };
  pagination?: IPaginationBaseState;
  setPagination?: (pagination: IPaginationBaseState) => void;
  setExpanded?: (expanded: Record<string, boolean>) => void;
};
export const useColumnFiltersQueryString = (options: UseColumnFiltersQueryStringProps) => {
  const { queryStringAPIFilterKeys, pagination, setPagination, setExpanded } = options;
  const { activePage, itemsPerPage, sort, order } = pagination || {};
  const sorting = `${sort},${order}`;

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const fitlersQueryString = useMemo(() => {
    const finalFilters: ColumnFiltersState = [...columnFilters];

    const queryStringWithoutPagination = getAPIFilterQueryString(0, 10000000, finalFilters, queryStringAPIFilterKeys, sorting);
    const queryStringWithPagination = getAPIFilterQueryString(
      activePage - 1,
      itemsPerPage,
      finalFilters,
      queryStringAPIFilterKeys,
      sorting,
    );
    return {
      queryStringWithoutPagination,
      queryStringWithPagination,
    };
  }, [columnFilters, pagination]);

  useEffect(() => {
    if (!pagination) return;

    if (columnFilters?.length > 0) {
      setPagination?.({ ...pagination, activePage: 1 });
      setExpanded?.({});
    }
  }, [columnFilters]);

  return {
    columnFilters,
    setColumnFilters,
    ...fitlersQueryString,
  };
};

export const getAPIFilterQueryString = (
  page?: number,
  size?: number,
  columnFilters?: ColumnFiltersState,
  queryAndTableColumnMapKeys?: { [key: string]: string },
  sorting?: string,
) => {
  const modifiedFilters = [] as any;
  columnFilters?.forEach(filter => {
    if (DATE_COLUMN_PHRASES.some(ph => filter?.id?.includes(ph)) && !['0', '1']?.includes(filter?.value as string)) {
      {
        modifiedFilters.push(
          ...[
            {
              id: filter.id + 'Start',
              value: (filter?.value as string) + 'T00:00:00Z',
            },
            {
              id: filter.id + 'End',
              value: (filter?.value as string) + 'T23:59:59Z',
            },
          ],
        );
      }
    } else {
      modifiedFilters.push(filter);
    }
  });
  const filterKeyValues = reduceQueriesArrayToObject(modifiedFilters, 'id', 'value', queryAndTableColumnMapKeys || {});
  return getQueryString({
    ...filterKeyValues,
    page: page || 0,
    size: size || 100000,
    sort: sorting,
  });
};

const reduceQueriesArrayToObject = (
  columnFilters: ColumnFiltersState,
  key: string,
  valueKey: string,
  queryKeyValues: { [key: string]: string },
) => {
  return columnFilters?.reduce((obj, item) => {
    const dateFilter = DATE_COLUMN_PHRASES.some(ph => item?.id?.includes(ph));
    obj[queryKeyValues[item[key]]] = dateFilter ? item[valueKey] : window.encodeURIComponent(item[valueKey]);
    return obj;
  }, {});
};

export const getQueryString = (obj: { [key: string]: string | number | undefined }) => {
  return Object.keys(obj).reduce((str, key, i) => {
    const delimiter = i === 0 ? '' : '&';
    const val = obj[key];

    return val || val === 0 ? `${str}${delimiter}${key}=${val}` : str;
  }, '');
};

export const DATE_COLUMN_PHRASES = ['date', 'Date', 'datetime', 'DateTime', 'time', 'Time'];
