import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntities as getCityEntities,
  getCountyEntities,
  getOwnerCityEntities,
  getOwnerCountyEntities,
} from 'app/entities/city/city.reducer';
import { getEntities as getStates } from 'app/entities/city/state.reducer';
import { getEntities as getNeighborhood } from 'app/entities/neighborhood/neighborhood.reducer';
import { defaultValue } from 'app/shared/model/device-location';
import { getFilterByStateAndCounty, reactStrapStyles } from 'app/shared/util/form-utils';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, useForm, useWatch } from 'react-hook-form';
import { Translate, ValidatedField, isEmail, translate } from 'react-jhipster';
import { Link, useBeforeUnload, useBlocker, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, FormFeedback, InputGroup, Label } from 'reactstrap';
import { getEntity as getApplicationUserEntity } from '../application-user/application-user.reducer';
import { getEntity as getDeviceEntity } from './device.reducer';
import { createEntity, getEntity, updateEntity } from './location/location.reducer';
import Maps from './maps';

enum FormLogic {
  INITIAL,
  RESET,
  LOADED,
  DONE,
}

export const DeviceLocation = () => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isDirty },
    reset: resetForm,
  } = useForm();

  const NOMINATIM_API_URL = 'https://nominatim.openstreetmap.org/search';
  const DEFAULT_CENTER = [51.505, -0.09] as [number, number];

  const dispatch = useAppDispatch();
  const [formLogic, setFormLogic] = useState<FormLogic>(FormLogic.INITIAL);
  const [position, setPosition] = useState<[number, number] | null>(null);
  const [locationData, setLocationData] = useState<string | null>(null);
  const [center, setCenter] = useState<[number, number]>(DEFAULT_CENTER);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [unsavedLocation, setUnsavedLocation] = useState<boolean>(false);

  useBeforeUnload(
    useCallback(
      event => {
        if (unsavedLocation) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [unsavedLocation],
    ),
  );

  const blocker = useBlocker(({ currentLocation, nextLocation }) => unsavedLocation && currentLocation.pathname !== nextLocation.pathname);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const userWantsToNavigate = window.confirm(`You have unsaved changes on "Location" tab. Are you sure you want to leave?`);
      if (userWantsToNavigate) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  const states = useAppSelector(state => state.states.menuOptions);
  const countiesOptions = useAppSelector(state => state.city.countyMenuOptions);
  const ownerCountiesOptions = useAppSelector(state => state.city.ownerCountyMenuOptions);

  const citiesOptions = useAppSelector(state => state.city.menuOptions);

  const ownerCitiesOptions = useAppSelector(state => state.city.ownerCityMenuOptions);
  const neighborhoodList = useAppSelector(state => state.neighborhood.entities);
  const applicationUserEntity = useAppSelector(state => state.applicationUser.entity);
  const applicationUserEntityLoading = useAppSelector(state => state.applicationUser.loading);
  const deviceEntity = useAppSelector(state => state.device.entity);

  const selectedDeviceState = useWatch({ name: 'deviceState', control });
  const selectedDeviceCity = useWatch({ name: 'deviceCity', control });
  const selectedDeviceCounty = useWatch({ name: 'deviceCounty', control });
  const watchDeviceZipCode = useWatch({ name: 'deviceZipCode', control });
  const watchDeviceAddress = useWatch({ name: 'deviceAddress', control });

  const deviceLocationEntity = useAppSelector(state => state.deviceLocation.entity);

  const updating = useAppSelector(state => state.deviceLocation.updating);

  const updateSuccess = useAppSelector(state => state.deviceLocation.updateSuccess);

  const { id: deviceId } = useParams<'id'>();
  const isNew = deviceId === undefined;

  useEffect(() => {
    setUnsavedLocation(isDirty);
  }, [isDirty, setUnsavedLocation]);

  const neighborhoodDeviceMenuOptions = neighborhoodList
    ?.filter(n => n.city?.id === selectedDeviceCity?.value)
    .map(neighborhoodEntity => ({ label: neighborhoodEntity?.name, value: neighborhoodEntity?.id }));

  useEffect(() => {
    dispatch(getNeighborhood({ filterUrl: `size=10000000&` }));
    dispatch(getStates({}));
  }, []);

  useEffect(() => {
    if (!isNew && formLogic === FormLogic.RESET) {
      dispatch(getEntity(deviceId));
      dispatch(getDeviceEntity(deviceId));
    }

    if (!isNew && deviceLocationEntity.id && deviceEntity?.id && formLogic === FormLogic.RESET) {
      const filterDeviceLocationUrl = getFilterByStateAndCounty(deviceLocationEntity.deviceState, deviceLocationEntity.deviceCounty);
      dispatch(getCountyEntities(deviceLocationEntity.deviceState));
      dispatch(getCityEntities({ filterUrl: filterDeviceLocationUrl }));
      dispatch(getApplicationUserEntity(deviceEntity?.user?.id));

      dispatch(getOwnerCityEntities({ county: deviceLocationEntity.ownerCounty, state: deviceLocationEntity.ownerState }));
      dispatch(getOwnerCountyEntities(deviceLocationEntity.ownerState));

      setFormLogic(FormLogic.LOADED);
    } else if (formLogic === FormLogic.INITIAL) {
      const { id, ...defaultLocationVals } = defaultValue;
      setFormLogic(FormLogic.RESET);
      resetForm(defaultLocationVals);
    }
  }, [isNew, resetForm, deviceLocationEntity, deviceEntity, formLogic]);

  useEffect(() => {
    if (
      deviceLocationEntity &&
      citiesOptions &&
      countiesOptions &&
      neighborhoodList &&
      states &&
      ownerCitiesOptions &&
      ownerCountiesOptions &&
      applicationUserEntity
    ) {
      if (formLogic === FormLogic.LOADED && Number(deviceLocationEntity.deviceId) === Number(deviceId) && !applicationUserEntityLoading) {
        const vals = {
          ...deviceLocationEntity,

          deviceCity: citiesOptions?.find(c => c.value === Number(deviceLocationEntity?.deviceCity)),
          deviceCounty: countiesOptions?.find(c => c.value === deviceLocationEntity?.deviceCounty),
          deviceState: states?.find(c => c.value === deviceLocationEntity?.deviceState),

          ownerCity: ownerCitiesOptions?.find(c => c.value === Number(deviceLocationEntity?.ownerCity)),
          ownerCounty: ownerCountiesOptions?.find(c => c.value === deviceLocationEntity?.ownerCounty),
          ownerState: states?.find(c => c.value === deviceLocationEntity?.ownerState),

          deviceNeighborhood: neighborhoodList?.find(n => n.id === Number(deviceLocationEntity.deviceNeighborhood))
            ? {
                label: neighborhoodList.find(n => n.id === Number(deviceLocationEntity.deviceNeighborhood)).name,
                value: deviceLocationEntity.deviceNeighborhood,
              }
            : undefined,
          ownerNeighborhood: neighborhoodList?.find(n => n.id === Number(deviceLocationEntity.ownerNeighborhood))
            ? {
                label: neighborhoodList.find(n => n.id === Number(deviceLocationEntity.ownerNeighborhood)).name,
                value: deviceLocationEntity.ownerNeighborhood,
              }
            : undefined,
          firstName: applicationUserEntity?.internalUser?.firstName,
          lastName: applicationUserEntity?.internalUser?.lastName,
          email: applicationUserEntity?.internalUser?.email,
          phone: applicationUserEntity?.phone,
        };

        setFormLogic(FormLogic.DONE);

        resetForm(vals);
      }
    }
  }, [
    deviceLocationEntity,
    applicationUserEntity,
    citiesOptions,
    countiesOptions,
    neighborhoodList,
    states,
    formLogic,
    ownerCitiesOptions,
    ownerCountiesOptions,
  ]);

  const resetMap = () => {
    setPosition(null);
    setCenter(DEFAULT_CENTER);
    setLocationData(null);
  };
  const searchLocation = async ({ street, city, state, postalCode }: { street; city; state; postalCode }) => {
    setLocationLoading(true);
    const response = await fetch(
      `${NOMINATIM_API_URL}?street=${encodeURIComponent(street)}&city=${encodeURIComponent(city)}&state=${encodeURIComponent(
        state,
      )}&postalcode=${encodeURIComponent(postalCode)}&format=json`,
    );
    const data = await response.json();
    setLocationLoading(false);
    if (data.length > 0) {
      const { lat, lon, display_name } = data[0];
      setPosition([parseFloat(lat), parseFloat(lon)]);
      setCenter([parseFloat(lat), parseFloat(lon)]);
      setLocationData(display_name);
      setLocationError(null);
    } else {
      resetMap();
      setLocationError(`Maps can't find this address.`);
    }
  };

  const debouncedSearchLocation = useMemo(
    () =>
      debounce((address: string) => {
        if (!selectedDeviceCity?.label || !selectedDeviceState?.label || !selectedDeviceCounty?.label || !watchDeviceZipCode || !address) {
          return;
        }
        searchLocation({
          street: address,
          city: selectedDeviceCity?.label,
          state: selectedDeviceState?.label,
          postalCode: watchDeviceZipCode,
        });
      }, 2000),
    [selectedDeviceCity, selectedDeviceState, selectedDeviceCounty, watchDeviceZipCode],
  );

  useEffect(() => {
    setLocationData(null);
    debouncedSearchLocation(watchDeviceAddress);
  }, [watchDeviceAddress, debouncedSearchLocation]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    if (deviceId) {
      values.deviceId = Number(deviceId);
    }
    const entity = {
      ...deviceLocationEntity,
      ...values,
      deviceCity: values?.deviceCity?.value,
      deviceCounty: values?.deviceCounty?.value,
      deviceState: values?.deviceState?.value,
      deviceNeighborhood: values?.deviceNeighborhood?.value,
      latitude: position?.[0] ?? null,
      longitude: position?.[1] ?? null,
    };

    if (isNew || !deviceLocationEntity.id) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
    resetForm(values, { keepValues: true });
  };

  useEffect(() => {
    if (updateSuccess) {
      toast.success('Location Updated.');
    }
  }, [updateSuccess]);

  return (
    <div className="location-tab">
      <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
        <div className="d-flex">
          <div className="form-body">
            <h2>Owner Information</h2>
            <div className="d-flex">
              <ValidatedField
                register={register}
                type="text"
                name="firstName"
                error={errors?.firstName as FieldError}
                label={translate('global.form.firstName.label')}
                disabled={true}
                validate={{
                  required: { value: true, message: translate('register.messages.validate.firstName.required') },
                  minLength: { value: 1, message: translate('register.messages.validate.firstName.minlength') },
                  maxLength: { value: 50, message: translate('register.messages.validate.firstName.maxlength') },
                }}
              />

              <ValidatedField
                register={register}
                type="text"
                name="lastName"
                error={errors?.lastName as FieldError}
                label={translate('global.form.lastName.label')}
                disabled={true}
                validate={{
                  required: { value: true, message: translate('register.messages.validate.lastName.required') },
                  minLength: { value: 1, message: translate('register.messages.validate.lastName.minlength') },
                  maxLength: { value: 50, message: translate('register.messages.validate.lastName.maxlength') },
                }}
              />
            </div>
            <div className="d-flex">
              <ValidatedField
                name="email"
                register={register}
                label={translate('global.form.email.label')}
                error={errors?.email as FieldError}
                type="email"
                disabled={true}
                validate={{
                  required: { value: true, message: translate('global.messages.validate.email.required') },
                  minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                  maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                  validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                }}
                data-cy="email"
              />

              <div className="d-flex">
                <ValidatedField
                  name="phone"
                  register={register}
                  label={translate('global.form.phone.label')}
                  error={errors?.phone as FieldError}
                  disabled={true}
                  data-cy="phone"
                />
              </div>
            </div>
            <h2>Device Location {locationLoading && <FontAwesomeIcon icon="sync" color="#439a97" spin={locationLoading} />}</h2>
            <div className="d-flex">
              <ValidatedField
                register={register}
                type="text"
                id="deviceAddress"
                data-cy="deviceAddress"
                name="deviceAddress"
                error={errors?.deviceAddress as FieldError}
                label="Address*"
                validate={{
                  required: { value: true, message: 'This field is required' },
                }}
              />

              <div style={{ display: 'flex' }} className="flex-column">
                <Label for="deviceState">{'State'}*</Label>
                <InputGroup className="mb-3" error={errors?.deviceState} data-testid="deviceState">
                  <Controller
                    control={control}
                    name="deviceState"
                    rules={{ required: 'State is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Select
                            menuPlacement="auto"
                            options={states}
                            classNamePrefix="deviceState"
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(e: any) => {
                              dispatch(getCountyEntities(e?.value));
                              resetMap();
                              setValue('deviceCity', null);
                              setValue('deviceCounty', null);
                              field.onChange(e);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ display: 'flex' }} className="flex-column">
                <Label for="deviceCounty">{'County'}*</Label>
                <InputGroup className="mb-3" error={errors?.deviceCounty} data-testid="deviceCounty">
                  <Controller
                    control={control}
                    name="deviceCounty"
                    rules={{ required: 'County is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Select
                            menuPlacement="auto"
                            classNamePrefix="deviceCounty"
                            options={countiesOptions || []}
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(e: any) => {
                              resetMap();
                              setValue('deviceCity', null);
                              const filterDeviceLocationUrl = getFilterByStateAndCounty(selectedDeviceState?.value, e.value);
                              dispatch(getCityEntities({ filterUrl: filterDeviceLocationUrl }));

                              field.onChange(e);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>

              <div style={{ display: 'flex' }} className="flex-column">
                <Label for="deviceCity">{'City'}*</Label>
                <InputGroup className="mb-3" error={errors?.deviceCity} data-testid="deviceCity">
                  <Controller
                    control={control}
                    name="deviceCity"
                    rules={{ required: 'City is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Select
                            menuPlacement="auto"
                            classNamePrefix="deviceCity"
                            options={citiesOptions}
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(e: any) => {
                              setValue('deviceNeighborhood', null);
                              field.onChange(e);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex">
              <ValidatedField
                register={register}
                type="number"
                id="deviceZipCode"
                data-cy="deviceZipCode"
                name="deviceZipCode"
                error={errors?.deviceZipCode as FieldError}
                label="Zipcode*"
                validate={{
                  required: { value: true, message: 'Zipcode is required' },
                  minLength: { value: 1, message: translate('register.messages.validate.zipcode.minlength') },
                  maxLength: { value: 16, message: translate('register.messages.validate.zipcode.maxlength') },
                }}
              />

              <div style={{ display: 'flex' }} className="flex-column">
                <Label for="deviceNeighborhood">{'Neighborhood*'}</Label>
                <InputGroup className="mb-3" error={errors?.countId} data-testid="deviceNeighborhood">
                  <Controller
                    control={control}
                    name="deviceNeighborhood"
                    rules={{ required: 'Neighborhood is required' }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Select
                            menuPlacement="auto"
                            classNamePrefix="deviceNeighborhood"
                            options={neighborhoodDeviceMenuOptions || []}
                            styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                            value={field.value}
                            onChange={(e: any) => {
                              field.onChange(e);
                            }}
                          />

                          <FormFeedback>{fieldState.error?.message}</FormFeedback>
                        </>
                      );
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex">
              <ValidatedField
                register={register}
                type="text"
                name="longitude"
                error={errors?.firstName as FieldError}
                label={translate('global.form.longitude')}
                disabled={true}
                value={position?.[0] ?? ''}
              />

              <ValidatedField
                register={register}
                type="text"
                name="latitude"
                error={errors?.lastName as FieldError}
                label={translate('global.form.latitude')}
                disabled={true}
                value={position?.[1] ?? ''}
              />
            </div>
          </div>
          <div style={{ width: '100%', marginLeft: '20px' }}>
            <Maps locationData={locationData} position={position} center={center} locationError={locationError}></Maps>
          </div>
        </div>
        <hr />
        <div className="justify-content-end d-flex form-footer">
          <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/device" replace color="info">
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DeviceLocation;
