import React, { useEffect } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { Area, AreaChart, CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useGraphDelta } from '../../utils/use-graph-delta';
import { GRAPHTYPE } from '../utils';
import moment from 'moment';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';

export const DeviceMetrics = () => {
  const location = useLocation();
  const { from } = location.state || { from: { path: '/device' } };

  return (
    <div className="metrics-tab">
      <Row>
        <Col md="4">
          <Card className="jh-card chart-card">
            <div className="chart-card-heading">
              <h3>Bandwidth</h3>
            </div>
            <hr />
            <Chart type={GRAPHTYPE.BANDWIDTH} />
          </Card>
        </Col>
        <Col md="4">
          <Card className="jh-card chart-card">
            <div className="chart-card-heading">
              <h3>LQI(link quality)</h3>
            </div>
            <hr />
            <Chart type={GRAPHTYPE.LINK_QUALITY} />
          </Card>
        </Col>
        <Col md="4">
          <Card className="jh-card chart-card">
            <div className="chart-card-heading">
              <h3>Latency</h3>
            </div>
            <hr />
            <Chart type={GRAPHTYPE.LATENCY} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Card className="jh-card chart-card">
            <div className="chart-card-heading">
              <h3>RSSI</h3>
            </div>
            <hr />
            <Chart type={GRAPHTYPE.RSSI} />
          </Card>
        </Col>
        <Col md="4">
          <Card className="jh-card chart-card">
            <div className="chart-card-heading">
              <h3>Modes</h3>
            </div>
            <hr />
            <Chart type={GRAPHTYPE.MODES} />
          </Card>
        </Col>
        <Col md="4">
          <Card className="jh-card chart-card">
            <div className="chart-card-heading">
              <h3>Voltage</h3>
            </div>
            <hr />
            <Chart type={GRAPHTYPE.VOLTAGE} />
          </Card>
        </Col>
      </Row>
      <hr />
      <div className="justify-content-end d-flex form-footer">
        <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to={from?.path} replace color="info">
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </div>
    </div>
  );
};

const Chart = ({ type }) => {
  const { id } = useParams<'id'>();
  const { data: graphData, error } = useGraphDelta({ type, timeRange: '7', deviceId: id });
  const getVal = x => {
    return Number(x.value[type?.toLowerCase()]);
  };
  return (
    <div className="d-flex justify-content-center" style={{ minHeight: '200px', alignItems: 'center' }}>
      {graphData?.length > 0 && !error ? (
        <ResponsiveContainer id="graph-data" width="95%" height={300}>
          <AreaChart data={graphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="1" y2="0">
                <stop offset="5%" stopColor="#E7E3F8" stopOpacity="0.8" />
                <stop offset="95%" stopColor="#EFEDFA" stopOpacity="0" />
                <stop offset="95%" stopColor="#F4F2FB" stopOpacity="0" />
              </linearGradient>
            </defs>
            <XAxis xAxisId="0" axisLine={false} dataKey="deviceReportedTime" tickFormatter={value => moment(value).format('HH:mm')} />
            <XAxis xAxisId="1" axisLine={false} dataKey="date" allowDuplicatedCategory={false} />
            <YAxis width={50} axisLine={false} dataKey={getVal} />
            <CartesianGrid />
            <Tooltip
              labelFormatter={value => {
                return value ? `Timestamp: ${moment(value).format('MM/DD/YY HH:mm:ss')}` : '';
              }}
              formatter={(value, name) => {
                return isNaN(Number(value)) ? '' : value;
              }}
            />
            <Area
              type="monotone"
              dataKey={getVal}
              stroke="#78BEDD"
              fillOpacity={1}
              fill="url(#colorValue)"
              activeDot={{ fill: '#806BD9', stroke: '#78BEDD', strokeWidth: 2, r: 5, className: 'boxShadow' }}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <EmptyGraph />
      )}
    </div>
  );
};

const EmptyGraph = () => {
  const data = [
    {
      name: '1',
      uv: 100,
    },

    {
      name: '2',
      uv: 100,
    },

    {
      name: '3',
      uv: 100,
    },
    {
      name: '4',
      uv: 100,
    },
    {
      name: '5',
      uv: 100,
    },
  ]; // Empty dataset

  return (
    <div className="d-flex justify-content-center no-data" style={{ minHeight: '200px', alignItems: 'center', position: 'relative' }}>
      <LineChart width={350} height={300} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis dataKey="uv" />
        <Tooltip />
        <Legend />
      </LineChart>
      <h3>No Data Available</h3>
    </div>
  );
};

export default DeviceMetrics;
