import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './neighborhood.reducer';

export const NeighborhoodDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const neighborhoodEntity = useAppSelector(state => state.neighborhood.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="neighborhoodDetailsHeading">
          <Translate contentKey="rainspotApp.neighborhood.detail.title">Neighborhood</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{neighborhoodEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="rainspotApp.neighborhood.name">Name</Translate>
            </span>
          </dt>
          <dd>{neighborhoodEntity.name}</dd>
          <dt>
            <span id="countId">
              <Translate contentKey="rainspotApp.neighborhood.countId">Count Id</Translate>
            </span>
          </dt>
          <dd>{neighborhoodEntity.countId}</dd>
          <dt>
            <span id="stateId">
              <Translate contentKey="rainspotApp.neighborhood.stateId">State Id</Translate>
            </span>
          </dt>
          <dd>{neighborhoodEntity.stateId}</dd>
          <dt>
            <span id="regionId">
              <Translate contentKey="rainspotApp.neighborhood.regionId">Region Id</Translate>
            </span>
          </dt>
          <dd>{neighborhoodEntity.regionId}</dd>
          <dt>
            <Translate contentKey="rainspotApp.neighborhood.city">City</Translate>
          </dt>
          <dd>{neighborhoodEntity.city ? neighborhoodEntity.city.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/neighborhood" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/neighborhood/${neighborhoodEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NeighborhoodDetail;
