export const mockData = [
  {
    id: 1151,
    name: 'Rainspot',
    dateCreated: '2024-02-08T19:00:00Z',
    dateUpdated: '2024-02-08T19:00:00Z',
    createdBy: 'Sania',
    modifiedBy: 'Sania',
    status: 'ACTIVE',
    address: 'DevTek Inc',
    city: '230',
    state: 'Alabama',
    zipCode: '44234',
    contact: 'Contact 1',
    contactEmail: 'contact@gmail.com',
    contactPhone: '1234567890',
  },
];

export const mockDataEdit = {
  id: 1151,
  name: 'Rainspot',
  dateCreated: '2024-02-08T19:00:00Z',
  dateUpdated: '2024-02-08T19:00:00Z',
  createdBy: 'Sania',
  modifiedBy: 'Sania',
  status: 'ACTIVE',
  streetAddress: 'DevTek Inc',
  city: '1',
  state: 'State 1',
  zipCode: '44234',
  contactInfo: [
    {
      name: 'Contact 1',
      email: 'contacts@gmail.com',
      phoneNo: '(283)-728-7387',
    },
  ],
};

export const ORG_TABLE_FILTER_QUERY = {
  name: 'name.contains',
  contact: 'contact.contains',
  address: 'address.contains',
  contactPhone: 'contactPhone.contains',
  contactEmail: 'contactEmail.contains',
};
