import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Messages from './messages';

const MessagesUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Messages />} />
  </ErrorBoundaryRoutes>
);

export default MessagesUserRoutes;
