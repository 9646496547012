import { IUser } from 'app/shared/model/user.model';
import { IDevice } from 'app/shared/model/device.model';
import { IComment } from 'app/shared/model/comment.model';
import { ILocation } from 'app/shared/model/location.model';
import { IOrganization } from 'app/shared/model/organization.model';

export interface IApplicationUser {
  id?: number;
  additionalField?: number | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  internalUser?: IUser | null;
  devices?: IDevice[] | null;
  comments?: IComment[] | null;
  locations?: ILocation[] | null;
  organization?: IOrganization | null;
  appUserId?: string | null;
}

export const defaultValue: Readonly<IApplicationUser> = {};
