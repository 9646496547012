import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { getEntities as getApplicationUsers } from 'app/entities/application-user/application-user.reducer';
import { getEntities as getCities } from 'app/entities/city/city.reducer';
import { getEntities as getLocations } from 'app/entities/location/location.reducer';
import { getEntities as getDevices } from 'app/entities/device/device.reducer';
import { getEntities as getNeighborhoods } from 'app/entities/neighborhood/neighborhood.reducer';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { DEVICESTATE } from 'app/shared/model/enumerations/devicestate.model';
import { createEntity, getEntity, reset, updateEntity } from './device.reducer';
import { DEFAULT_ORGANIZATION } from '../application-user/utils';

export const DeviceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const applicationUsers = useAppSelector(state => state.applicationUser.entities);
  const organizations = useAppSelector(state => state.organization.entities);
  const locations = useAppSelector(state => state.location.entities);
  const devices = useAppSelector(state => state.device.entities);
  const cities = useAppSelector(state => state.city.entities);
  const neighborhoods = useAppSelector(state => state.neighborhood.entities);
  const deviceEntity = useAppSelector(state => state.device.entity);
  const loading = useAppSelector(state => state.device.loading);
  const updating = useAppSelector(state => state.device.updating);
  const updateSuccess = useAppSelector(state => state.device.updateSuccess);

  const handleClose = () => {
    navigate('/device' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getApplicationUsers({}));
    dispatch(getOrganizations({}));
    dispatch(getCities({}));
    dispatch(getNeighborhoods({}));
    dispatch(getDevices({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.hopDepth !== undefined && typeof values.hopDepth !== 'number') {
      values.hopDepth = Number(values.hopDepth);
    }
    values.dateCreated = convertDateTimeToServer(values.dateCreated);
    values.dateUpdated = convertDateTimeToServer(values.dateUpdated);

    const entity = {
      ...deviceEntity,
      ...values,
      manager: devices.find(it => it?.id?.toString() === values?.managerId?.toString()),
      user: applicationUsers.find(it => it?.id?.toString() === values?.user?.toString()),
      org: organizations.find(it => it?.id?.toString() === values?.org?.toString()),
      location:
        Array.isArray(locations) && locations?.length > 0
          ? locations?.find(it => it?.id?.toString() === values?.location?.toString())
          : null,
      city: cities.find(it => it?.id?.toString() === values?.city?.toString()),
      neighborhood: neighborhoods.find(it => it?.id?.toString() === values?.neighborhood?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dateCreated: displayDefaultDateTime(),
          dateUpdated: displayDefaultDateTime(),
          org: organizations?.find(it => it?.name?.toString()?.toUpperCase() === DEFAULT_ORGANIZATION)?.id,
        }
      : {
          state: 'ON',
          ...deviceEntity,
          dateCreated: convertDateTimeFromServer(deviceEntity?.dateCreated),
          dateUpdated: convertDateTimeFromServer(deviceEntity?.dateUpdated),
          user: deviceEntity?.user?.id,
          org: deviceEntity?.org?.id,
          location: deviceEntity?.location?.id,
          mesh: deviceEntity?.mesh?.id,
          city: deviceEntity?.city?.id,
          neighborhood: deviceEntity?.neighborhood?.id,
          managerId: deviceEntity?.manager?.id,
        };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="rainspotApp.device.home.createOrEditLabel" data-cy="DeviceCreateUpdateHeading">
            <Translate contentKey={isNew ? `rainspotApp.device.home.createLabel` : `rainspotApp.device.home.editLabel`}>
              Create or edit a Device
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('rainspotApp.device.name')}
                id="device-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('rainspotApp.device.serialNumber')}
                id="device-serialNumber"
                name="serialNumber"
                data-cy="serialNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={'Address'}
                id="device-installedAddress"
                name="installedAddress"
                data-cy="installedAddress"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={'Zip Code'}
                id="device-installedAddressZipcode"
                name="installedAddressZipcode"
                data-cy="installedAddressZipcode"
                type="number"
                validate={{
                  maxLength: { value: 16, message: translate('register.messages.validate.zipcode.maxlength') },
                }}
              />
              <ValidatedField
                id="device-org"
                name="org"
                data-cy="org"
                label={translate('rainspotApp.device.org')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0">
                  Select...
                </option>
                {organizations
                  ? organizations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="device-neighborhood"
                name="neighborhood"
                data-cy="neighborhood"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                label={translate('rainspotApp.device.neighborhood')}
                type="select"
              >
                <option value="" key="0">
                  Select...
                </option>
                {neighborhoods
                  ? neighborhoods.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="device-managerId" name="managerId" data-cy="managerId" label={'Manager Device'} type="select">
                <option value="" key="0">
                  Select...
                </option>
                {devices
                  ? devices
                      ?.filter(d => !d?.manager)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                  : null}
              </ValidatedField>

              <hr />
              <div className="justify-content-end d-flex form-footer">
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/device" replace color="info">
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default DeviceUpdate;
