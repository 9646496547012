export enum Region {
  NORTHEAST = 'NORTHEAST',

  SOUTHWEST = 'SOUTHWEST',

  WEST = 'WEST',

  SOUTHEAST = 'SOUTHEAST',

  MIDWEST = 'MIDWEST',
}
