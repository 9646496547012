import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Inventory from './inventory';
import InventoryUpdate from './inventory-update';
import DeviceDeleteDialog from './inventory-delete-dialog';
import DeviceDetail from '../device/device-detail';

const InventoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Inventory />} />
    <Route path="new" element={<InventoryUpdate />} />
    <Route path=":id">
      <Route index element={<DeviceDetail />} />
      <Route path="edit" element={<InventoryUpdate />} />
      <Route path="delete" element={<DeviceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InventoryRoutes;
