import dayjs from 'dayjs';
import { IApplicationUser } from 'app/shared/model/application-user.model';
import { ICity } from 'app/shared/model/city.model';
import { IDevice } from 'app/shared/model/device.model';
import { ISerialNumberRange } from 'app/shared/model/serial-number-range.model';

export interface IOrganization {
  id?: number;
  name?: string;
  dateCreated?: dayjs.Dayjs | null;
  dateUpdated?: dayjs.Dayjs | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  users?: IApplicationUser[] | null;
  cities?: ICity[] | null;
  devices?: IDevice[] | null;
  serialNumberRanges?: ISerialNumberRange[] | null;
}

export const defaultValue: Readonly<IOrganization> = {};
