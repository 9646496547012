import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IDeviceReportedMetric, defaultValue } from 'app/shared/model/device-reported-metric.model';
import { getEntitiesView } from '../application-user/application-user.reducer';

const initialState: EntityState<IDeviceReportedMetric> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'data/api/device-reported-metrics';

// Actions

export const getEntities = createAsyncThunk(
  'deviceReportedMetric/fetch_entity_list',
  async ({ page, size, sort, filterUrl }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${filterUrl ?? ''}${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }&cacheBuster=${new Date().getTime()}`;
    return axios.get<IDeviceReportedMetric[]>(requestUrl);
  },
);
export const getViewEntities = createAsyncThunk(
  'deviceReportedMetric/fetch_entity_list',
  async ({ page, size, sort, filterUrl }: IQueryParams) => {
    const requestUrl = `${apiUrl}/views?${filterUrl ?? ''}${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }&cacheBuster=${new Date().getTime()}`;
    return axios.get<IDeviceReportedMetric[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'deviceReportedMetric/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDeviceReportedMetric>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);
export const getEntityHeatlth = createAsyncThunk(
  'deviceReportedMetric/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/health/${id}`;
    return axios.get<IDeviceReportedMetric>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'deviceReportedMetric/create_entity',
  async (entity: IDeviceReportedMetric, thunkAPI) => {
    const result = await axios.post<IDeviceReportedMetric>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'deviceReportedMetric/update_entity',
  async (entity: IDeviceReportedMetric, thunkAPI) => {
    const result = await axios.put<IDeviceReportedMetric>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'deviceReportedMetric/partial_update_entity',
  async (entity: IDeviceReportedMetric, thunkAPI) => {
    const result = await axios.patch<IDeviceReportedMetric>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'deviceReportedMetric/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDeviceReportedMetric>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const DeviceReportedMetricSlice = createEntitySlice({
  name: 'deviceReportedMetric',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntityHeatlth), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntityHeatlth), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesView), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          // device-payload data
          payload: data?.map(d => {
            return { id: d.id, value: d.value, deviceReportedTime: d.deviceReportedTime, attribute: d.attribute };
          }),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = DeviceReportedMetricSlice.actions;

// Reducer
export default DeviceReportedMetricSlice.reducer;
