import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  Updater,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect } from 'react';
import { JhiPagination } from 'react-jhipster';
import { Col, Table } from 'reactstrap';
import { FilterDate, FilterInput } from './filterTableInput';
import { DATE_COLUMN_PHRASES } from 'app/shared/util/table-utils';

type ReactTableProps = {
  columns: ColumnDef<any>[];
  data: any[];
  totalItems: number | undefined;
  handlePagination: (page: number) => void;
  onRowClick?: (e: any, row: any) => void;
  setColumnFilters?: (updater: Updater<ColumnFiltersState>) => void;
  columnFilters?: ColumnFiltersState;
  isLoading?: boolean;
  isExpandible?: boolean;
  setPaginationState: (paginationState: any) => void;
  expandedState?: ExpandedState;
  paginationState: {
    activePage: number;
    itemsPerPage: number;
    sort?: string;
    order?: string;
  };
};

function ReactTable({
  columns,
  data,
  totalItems,
  paginationState,
  handlePagination,
  setPaginationState,
  onRowClick,
  isExpandible,
  isLoading,
  columnFilters,
  setColumnFilters,
  expandedState,
}: ReactTableProps) {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const table = useReactTable({
    data,
    state: {
      ...columnFilters,
      expanded,
    },

    columns,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
  });

  useEffect(() => {
    if (expandedState) setExpanded(expandedState);
  }, [expandedState]);

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState?.sort;
    const order = paginationState?.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const sort = p => () => {
    setExpanded({});
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };
  const getColumnMaxMinWidths = (column: any) => {
    const columnWidth = column?.getSize() + 'px';

    return { style: { maxWidth: columnWidth, minWidth: columnWidth } };
  };

  return (
    <>
      <div className="react-table-wrapper">
        <Table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => {
              return (
                <>
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <th {...getColumnMaxMinWidths(header.column)} key={header.id} id={header.id} onClick={sort(header.id)}>
                          <span style={{ marginLeft: isExpandible && header.index === 0 ? '12px' : 'none' }}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}{' '}
                          </span>
                          <FontAwesomeIcon icon={getSortIconByFieldName(header.id)} />
                        </th>
                      );
                    })}
                  </tr>
                  {!!setColumnFilters && (
                    <tr key={'filter-' + headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <th
                            className="filter-header"
                            style={{ background: 'white !important' }}
                            {...getColumnMaxMinWidths(header.column)}
                            key={header.id}
                            id={'filter-' + header.id}
                            onClick={sort(header.id)}
                          >
                            {header.column.getCanFilter() ? (
                              DATE_COLUMN_PHRASES.some(ph => header.column?.id?.includes(ph)) ? (
                                <FilterDate column={header.column} />
                              ) : (
                                <FilterInput column={header.column} />
                              )
                            ) : null}
                          </th>
                        );
                      })}
                    </tr>
                  )}
                </>
              );
            })}
          </thead>
          <tbody>
            {data?.length < 1 ? (
              <tr>
                <td colSpan={100}>
                  <center style={{ minHeight: '200px', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {'No records found.'}</div>
                  </center>
                </td>
              </tr>
            ) : (
              <>
                {table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      {...(!onRowClick && { style: { cursor: 'auto' } })}
                      key={row.id}
                      onClick={e => {
                        onRowClick?.(e, row);
                      }}
                    >
                      {row.getVisibleCells().map(cell => {
                        const value = flexRender(cell.column.columnDef.cell, cell.getContext());
                        const colIndex = (cell.column.columnDef?.meta as any)?.index;
                        const tableTree = colIndex === 0 ? 'table-tree' : '';
                        const subRow = row.depth === 1 ? 'subRow' : '';
                        return (
                          <td key={cell.id} className={`${tableTree} ${subRow}`} {...getColumnMaxMinWidths(cell.column)}>
                            {value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </div>
      <Col md="12" style={{ marginTop: '1rem' }}>
        {totalItems ? (
          <div className={data && data.length > 0 ? '' : 'd-none'}>
            {/* <div className="justify-content-end d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
        </div> */}
            <div className="justify-content-end d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={2}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </Col>
    </>
  );
}

export default ReactTable;
