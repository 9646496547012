import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import React, { useEffect, useRef } from 'react';

const Messages = () => {
  const dispatch = useAppDispatch();

  const authToken = JSON.parse(localStorage.getItem('jhi-authenticationToken') || sessionStorage.getItem('jhi-authenticationToken'));

  const account = useAppSelector(state => state.authentication.account);
  const isAccountLoading = useAppSelector(state => state.authentication.loading);

  let iframeUrl = REACT_APP_CRM_URL + '?platform=1&entity=all';

  const iframe = useRef<HTMLIFrameElement>(null);

  const sendMessage = () => {
    iframe?.current?.contentWindow?.postMessage({ token: authToken, user: account, payload: { entity: 'all' } }, '*');
  };

  // const toast = useToast()

  useEffect(() => {
    window.addEventListener('message', (event: any) => {
      if (event.data.messagesLoaded) {
        sendMessage();
      }
    });
    dispatch(getSession());
  }, []);

  return (
    <>
      {isAccountLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="device-message-tab">
          <iframe id="messagesPortalIframe" ref={iframe} src={iframeUrl} width="100%" height="100%" title="Messages"></iframe>
        </div>
      )}
    </>
  );
};

export default Messages;
