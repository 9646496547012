import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FileInput } from 'app/modules/common/fileInput';
import { readFileContent } from 'app/shared/util/file-utils';
import { reactStrapStyles } from 'app/shared/util/form-utils';
import React, { useEffect, useMemo } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Translate, ValidatedField } from 'react-jhipster';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, Col, FormFeedback, InputGroup, Label, Row } from 'reactstrap';
import { getEntity, updateMeshCommand, updateEntity } from '../device.reducer';
import { mapDeviceEntityToFormValues, mapToMeshPayload } from '../utils';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const masterOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
export const sprinklerOptions = [
  { value: 'ON', label: 'ON' },
  { value: 'OFF', label: 'OFF' },
  { value: 'AUTO', label: 'AUTO' },
];
export const DeviceConfiguration = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();

  const deviceEntity = useAppSelector(state => state.device.entity);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const saveEntity = async values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    const firmwareBlob = await readFileContent(values.file?.[0]);
    const firmware = {
      path: values.file?.[0]?.name,
      fileType: values.file?.[0]?.name,
      fileObject: firmwareBlob,
      fileObjectContentType: values.file?.[0]?.type,
    };
    const entity = {
      ...values,
      city: {
        id: values?.city?.id,
      },
      // user: values?.user?.id,
      master: values?.master?.value,
      broadcastSSID: values?.broadcastSSID?.value,
      sprinklerStatus: values?.sprinkler?.value,
      firmware,
    };
    delete entity.file;
    delete entity.masterVal;
    if (deviceEntity.master !== values?.master?.value || deviceEntity?.sprinklerStatus !== values?.sprinkler?.value) {
      const meshPayload = mapToMeshPayload({ values, deviceEntity });
      dispatch(updateMeshCommand(meshPayload));
    }
    dispatch(updateEntity(entity));
  };
  return <DeviceConfigurationForm saveEntity={saveEntity} deviceEntity={deviceEntity} />;
};

export const DeviceConfigurationForm = ({ saveEntity, deviceEntity }) => {
  const formControl = useForm();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset: resetForm,
  } = formControl;

  const defaultValues = useMemo(() => {
    return mapDeviceEntityToFormValues({
      deviceEntity,
    });
  }, [deviceEntity]);

  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [resetForm, defaultValues]);

  const isDeviceOwner = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVICE_OWNER]));
  return (
    <div className="configuration-tab">
      <Row className="justify-content-center">
        {/* <div className="mt-10">&nbsp;</div> */}
        <Col md="12">
          <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
            <div className="form-container">
              <div className="form-body">
                <div className="d-flex">
                  <ValidatedField
                    register={register}
                    type="text"
                    id="device-name"
                    data-cy="device-name"
                    name="name"
                    error={errors?.name as FieldError}
                    label="Device Name*"
                    validate={{
                      required: { value: true, message: 'This field is required' },
                    }}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="serial-no"
                    data-cy="serial-no"
                    name="serialNumber"
                    error={errors?.serialNumber as FieldError}
                    label="Serial No."
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="device-status"
                    data-cy="device-status"
                    name="state"
                    error={errors?.state as FieldError}
                    label="Device Status"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="timezone"
                    data-cy="timezone"
                    name="timezone"
                    error={errors?.timezone as FieldError}
                    label="Timezone"
                    disabled={true}
                  />
                </div>

                <div className="d-flex">
                  <ValidatedField
                    register={register}
                    type="text"
                    id="ipv4-address"
                    data-cy="ipv4-address"
                    name="ipv4"
                    error={errors?.ipv4 as FieldError}
                    label="IPv4 Address"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="ipv6-address"
                    data-cy="ipv6-address"
                    name="ipv6"
                    error={errors?.ipv6 as FieldError}
                    label="IPv6 Address"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="subnet"
                    data-cy="subnet"
                    name="subnet"
                    error={errors?.subnet as FieldError}
                    label="Subnet"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="mac-address"
                    data-cy="mac-address"
                    name="macAddress"
                    error={errors?.macAddress as FieldError}
                    label="Mac Address"
                    disabled={true}
                  />
                </div>

                <div className="d-flex">
                  <ValidatedField
                    register={register}
                    type="text"
                    id="frequency"
                    data-cy="frequency"
                    name="frequency"
                    error={errors?.frequency as FieldError}
                    label="Frequency"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="hop-depth"
                    data-cy="hop-depth"
                    name="hopDepth"
                    error={errors?.hopDepth as FieldError}
                    label="Hop Depth"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="sprinkler-status"
                    data-cy="sprinkler-status"
                    name="sprinklerStatus"
                    error={errors?.sprinklerStatus as FieldError}
                    label="Sprinkler Status"
                    disabled={true}
                  />

                  <ValidatedField
                    register={register}
                    type="text"
                    id="master"
                    data-cy="master"
                    name="masterVal"
                    value={deviceEntity?.master ? 'Yes' : 'No'}
                    error={errors?.master as FieldError}
                    label="Master"
                    disabled={true}
                  />
                </div>

                <hr />

                <div className="d-flex" style={{ marginTop: '35px' }}>
                  <ValidatedField
                    register={register}
                    type="text"
                    id="ssid"
                    data-cy="ssid-name"
                    name="ssid"
                    error={errors?.ssid as FieldError}
                    label="SSID Name"
                    disabled={false}
                  />

                  <div style={{ display: 'flex' }} className="flex-column">
                    <Label for="broadcastSSID">{'Broadcast SSID'}</Label>
                    <InputGroup className="mb-3" error={errors?.broadcastSSID} id="broadcastSSID" data-testid="sprinkler">
                      <Controller
                        control={control}
                        name="broadcastSSID"
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Select
                                menuPlacement="auto"
                                classNamePrefix="broadcast"
                                options={masterOptions}
                                styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                                value={field.value}
                                onChange={option => field.onChange(option)}
                              />
                              <FormFeedback>{fieldState.error?.message}</FormFeedback>
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </div>

                  {!isDeviceOwner && (
                    <>
                      <div style={{ display: 'flex' }} className="flex-column">
                        <Label for="sprinkler">{'Sprinkler'}</Label>
                        <InputGroup className="mb-3" error={errors?.sprinkler} id="sprinkler" data-testid="sprinkler">
                          <Controller
                            control={control}
                            name="sprinkler"
                            render={({ field, fieldState }) => {
                              return (
                                <>
                                  <Select
                                    menuPlacement="auto"
                                    classNamePrefix="sprinkler"
                                    options={sprinklerOptions}
                                    styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                                    value={field.value}
                                    onChange={option => field.onChange(option)}
                                  />
                                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                                </>
                              );
                            }}
                          />
                        </InputGroup>
                      </div>

                      <div style={{ display: 'flex' }} className="flex-column">
                        <Label for="master">{'Master'}</Label>
                        <InputGroup className="mb-3" error={errors?.master} id="master-command" data-testid="master">
                          <Controller
                            control={control}
                            name="master"
                            render={({ field, fieldState }) => {
                              return (
                                <>
                                  <Select
                                    menuPlacement="auto"
                                    options={masterOptions}
                                    classNamePrefix="master"
                                    styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                                    value={field.value}
                                    onChange={option => field.onChange(option)}
                                  />
                                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                                </>
                              );
                            }}
                          />
                        </InputGroup>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex">
                  {!isDeviceOwner && (
                    <div style={{ display: 'flex' }} className="flex-column">
                      <FileInput label={'Firmware'} name="file" formControl={formControl} s3url={deviceEntity?.firmwareURL} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <hr />
            <div className="justify-content-end d-flex form-footer">
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to={''} replace color="info">
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-configuration"
                data-testid="save-configuration"
                data-cy="saveConfigurationButton"
                type="submit"
              >
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};
export default DeviceConfiguration;
