import moment from 'moment';

export const decisionOption = [
  {
    value: 'all',
    label: `All decisions`,
  },
  ...[...Array(3).keys()].map(d => ({
    value: (d + 1 + '').padStart(2, '0'),
    label: `Decision ${d + 1}`,
  })),
];

export const runtimesOptions = [0, 6, 12, 18].map(r => ({
  value: (r + '').padStart(2, '0'),
  label: moment().startOf('day').add(r, 'hours').format('H[th] [hour]'),
}));
