import React from 'react';

function NeighborhoodIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="Map">
        <path
          id="Vector"
          d="M10 3.33332V13.6667M6 2.66666L6 12M2 3.81383V12.9213C2 13.4169 2.52154 13.7392 2.96481 13.5176L5.40372 12.2981C5.77909 12.1104 6.22091 12.1104 6.59629 12.2981L9.40372 13.7018C9.77909 13.8895 10.2209 13.8895 10.5963 13.7018L13.6315 12.1843C13.8573 12.0713 14 11.8405 14 11.588V2.92494C14 2.4699 13.5542 2.14859 13.1225 2.29248L10.4216 3.19278C10.1479 3.28401 9.85205 3.28401 9.57836 3.19278L6.42164 2.14054C6.14795 2.04931 5.85205 2.04931 5.57836 2.14054L2.45585 3.18137C2.18362 3.27212 2 3.52688 2 3.81383Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default NeighborhoodIcon;
