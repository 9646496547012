import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useMemo } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { ValidatedField, translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { mapHealthEntityToFormValues } from '../utils';
import { getEntityHeatlth } from '../../device-reported-metric/device-reported-metric.reducer';
import { getEntity } from '../device.reducer';

export const DeviceHealth = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntityHeatlth(id));
  }, []);

  const deviceEntity = useAppSelector(state => state.device.entity);
  const deviceHealth = useAppSelector(state => state.deviceReportedMetric.entity);

  const {
    register,
    formState: { errors },
    reset: resetForm,
  } = useForm();

  const defaultValues = useMemo(() => {
    if (!deviceEntity) {
      return;
    }
    return mapHealthEntityToFormValues({ entity: deviceEntity, health: deviceHealth });
  }, [deviceEntity, deviceHealth]);

  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [defaultValues, resetForm]);

  return (
    <>
      <div className="form-body">
        <Row className="justify-content-start">
          <form>
            <div className="form-body">
              <div className="d-flex">
                <ValidatedField
                  label={'Device Name'}
                  id="deviceName"
                  name="deviceName"
                  register={register}
                  disabled={true}
                  error={errors.deviceName as FieldError}
                  data-cy="deviceName"
                  type="text"
                />
                <ValidatedField
                  label={translate('rainspotApp.device.serialNumber')}
                  id="serialNumber"
                  name="serialNumber"
                  data-cy="serialNumber"
                  register={register}
                  disabled={true}
                  error={errors.serialNumber as FieldError}
                  type="text"
                />
              </div>
              <div className="d-flex">
                <ValidatedField
                  label={'Signal strength'}
                  id="signalStrength"
                  name="signalStrength"
                  data-cy="signalStrength"
                  type="text"
                  disabled={true}
                  register={register}
                  error={errors.signalStrength as FieldError}
                />
                <ValidatedField
                  label={'Upload'}
                  id="upload"
                  name="upload"
                  disabled={true}
                  register={register}
                  error={errors.upload as FieldError}
                  data-cy="upload"
                  type="text"
                />
                <ValidatedField
                  label={'Download'}
                  id="download"
                  disabled={true}
                  name="download"
                  register={register}
                  error={errors.download as FieldError}
                  data-cy="download"
                  type="text"
                />
              </div>
            </div>
          </form>
        </Row>
      </div>
    </>
  );
};

export default DeviceHealth;
