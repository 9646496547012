import React, { useContext, useEffect, useMemo } from 'react';
import { Translate, ValidatedField, isEmail, translate } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, FormFeedback, InputGroup, Label, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getAuthorities } from 'app/entities/authorities/authorities.reducer';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { reset as resetRegistration } from 'app/modules/account/register/register.reducer';
import { reactStrapStyles } from 'app/shared/util/form-utils';
import { Controller, FieldError, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { OrganizationContext } from '../organization/organization-update';
import { inviteApplicationUser, reset } from './application-user.reducer';
import { DEFAULT_ORGANIZATION, mapFormValuesToInviteEntity } from './utils';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const InviteUserWrapper = () => {
  return (
    <Card className="jh-card">
      <InviteUser />
    </Card>
  );
};

export const InviteUser = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const organizationContext = useContext(OrganizationContext);
  const {
    id: organizationId,
    setOrganizationUser,
    setMode,
  } = organizationContext ?? { id: null, setOrganizationUser: null, organizationUser: null, setMode: null };

  const fromOrganization = !!organizationId;

  const organizationOptions = useAppSelector(state => state.organization.menuOptions);
  const authoritiesOption = useAppSelector(state => state.authorities.entities);
  const loading = useAppSelector(state => state.applicationUser.loading);
  const updateSuccess = useAppSelector(state => state.applicationUser.updateSuccess);
  const loggedInUser = useAppSelector(state => state.authentication.internalUser);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('register.messages.invite'));
      if (!fromOrganization) {
        navigate('/application-user');
      } else {
        setMode(null);
        setOrganizationUser(null);
      }
      dispatch(resetRegistration());
    }
  }, [updateSuccess]);

  useEffect(() => {
    dispatch(getOrganizations({}));
    dispatch(getAuthorities({}));
  }, []);

  // eslint-disable-next-line complexity
  const onSubmit = values => {
    dispatch(reset());
    const entity = mapFormValuesToInviteEntity({ values });
    dispatch(inviteApplicationUser(entity));
  };

  return (
    <>
      <Row className="justify-content-start">
        <Col md="12">
          <h2 id="rainspotApp.applicationUser.home.createOrEditLabel" data-cy="ApplicationUserCreateUpdateHeading">
            Invite User
          </h2>
        </Col>
      </Row>
      <div className="mt-10">&nbsp;</div>
      <Row className="justify-content-start">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <InviteUserForm
              onSubmit={onSubmit}
              organizationOptions={organizationOptions}
              authoritiesOption={authoritiesOption}
              loggedInUser={loggedInUser}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export const InviteUserForm = ({ onSubmit, organizationOptions, authoritiesOption, loggedInUser }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm();

  const organizationContext = useContext(OrganizationContext);
  const {
    setMode,
    setOrganizationUser,
    id: organizationId,
  } = organizationContext ?? { setMode: null, setOrganizationUser: null, id: null };

  const isOrganizationRole = loggedInUser?.internalUser?.authority.some(r =>
    [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER].includes(r),
  );

  const navigate = useNavigate();
  const watchAuthorities = watch('authorities');
  const isOrganizationBased = !!organizationId || isOrganizationRole;
  const isOrganizationDisabled = !['ROLE_UTILITY_ADMIN', 'ROLE_UTILITY_USER'].includes(watchAuthorities?.value) || isOrganizationBased;

  /* If logged in user is an organization user or if the invite screen is opened from the context of organization */
  useEffect(() => {
    if (organizationId) {
      setValue('organizationId', organizationOptions?.find(o => o?.value?.toString() === organizationId?.toString()));
    } else if (isOrganizationRole) {
      setValue('organizationId', organizationOptions?.find(o => o?.value?.toString() === loggedInUser?.organization?.id?.toString()));
    }
  }, [organizationId, loggedInUser]);

  return (
    <form data-testid="application-user-form" onSubmit={vals => void handleSubmit(onSubmit)(vals)}>
      <div className="form-body">
        <div className="d-flex">
          <ValidatedField
            name="email"
            register={register}
            label={translate('global.form.email.label')}
            error={errors?.email as FieldError}
            type="email"
            validate={{
              required: { value: true, message: translate('global.messages.validate.email.required') },
              minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
              maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
              validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
            }}
            data-cy="email"
          />
        </div>
        <div className="d-flex">
          <ValidatedField
            register={register}
            type="text"
            name="firstName"
            error={errors?.firstName as FieldError}
            label={translate('global.form.firstName.label')}
            validate={{
              required: { value: true, message: translate('register.messages.validate.firstName.required') },
              minLength: { value: 1, message: translate('register.messages.validate.firstName.minlength') },
              maxLength: { value: 50, message: translate('register.messages.validate.firstName.maxlength') },
            }}
          />

          <ValidatedField
            register={register}
            type="text"
            name="lastName"
            error={errors?.lastName as FieldError}
            label={translate('global.form.lastName.label')}
            validate={{
              required: { value: true, message: translate('register.messages.validate.lastName.required') },
              minLength: { value: 1, message: translate('register.messages.validate.lastName.minlength') },
              maxLength: { value: 50, message: translate('register.messages.validate.lastName.maxlength') },
            }}
          />
        </div>
        <div className="d-flex">
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="roleId">{'Account type'}*</Label>
            <InputGroup className="mb-3" error={errors?.authorities} data-testid="authorities">
              <Controller
                control={control}
                name="authorities"
                rules={{ required: 'Account Type is required' }}
                render={({ field, fieldState }) => {
                  const options = !isOrganizationBased
                    ? authoritiesOption
                    : authoritiesOption.filter(op => op.name === 'ROLE_UTILITY_ADMIN' || op.name === 'ROLE_UTILITY_USER');
                  return (
                    <>
                      <Select
                        classNamePrefix="accountType"
                        menuPlacement="auto"
                        options={options?.map(a => ({ value: a?.name, label: a?.title }))}
                        styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                        value={field.value}
                        onChange={(option: any) => {
                          field.onChange(option);
                          if (option?.value !== 'ROLE_UTILITY_ADMIN' && option?.value !== 'ROLE_UTILITY_USER') {
                            setValue(
                              'organizationId',
                              organizationOptions?.find(o => o?.label?.toString()?.toUpperCase() === DEFAULT_ORGANIZATION),
                            );
                            clearErrors('organizationId');
                          } else if (!isOrganizationBased) {
                            setValue('organizationId', null);
                            clearErrors('organizationId');
                          }
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="organizationId">{isOrganizationDisabled ? 'Organization' : 'Organization*'}</Label>
            <InputGroup className="mb-3" error={errors?.organizationId} data-testid="organizationId">
              <Controller
                control={control}
                name="organizationId"
                rules={{
                  required: isOrganizationDisabled ? false : 'Organization is required',
                }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        menuPlacement="auto"
                        classNamePrefix="org"
                        options={organizationOptions}
                        styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                        isDisabled={isOrganizationDisabled}
                        value={field.value}
                        onChange={(option: any) => {
                          field.onChange(option);
                        }}
                      />
                    </>
                  );
                }}
              />
              <FormFeedback>{errors?.organizationId?.message as string}</FormFeedback>
            </InputGroup>
          </div>
        </div>
      </div>
      <hr className="solid-divider" />
      <div className={`d-flex  justify-content-end`}>
        <div className="d-flex justify-content-end form-footer">
          <Button
            id="cancel-save"
            data-cy="entityCreateCancelButton"
            onClick={() => {
              if (setOrganizationUser) {
                setMode(null);
              } else {
                navigate('/application-user');
              }
            }}
            replace
            color="info"
          >
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.cancel">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button color="primary" id="save-entity" data-testid="save-entity" data-cy="entityCreateSaveButton" type="submit">
            <Translate contentKey="entity.action.save">Link</Translate>
          </Button>
        </div>
      </div>
    </form>
  );
};
export default InviteUser;
