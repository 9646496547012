import React from 'react';

function MetricIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.66663 2.66667H3.99996C3.26358 2.66667 2.66663 3.26362 2.66663 4V9.33333C2.66663 10.0697 3.26358 10.6667 3.99996 10.6667H12C12.7363 10.6667 13.3333 10.0697 13.3333 9.33333V8.66667M11.6427 3.33333C12.1362 3.33333 12.567 3.60142 12.7976 3.99989M11.6427 3.33333C11.1492 3.33333 10.7184 3.60141 10.4878 3.99989M11.6427 3.33333V2M11.6427 7.33333V5.97783M13.9521 3.33333L12.7976 3.99989M9.33329 6L10.4878 5.33345M13.9521 6L12.7976 5.33347M9.33329 3.33333L10.4878 3.99989M10.4878 5.33345C10.3743 5.13731 10.3094 4.90957 10.3094 4.66667C10.3094 4.42376 10.3743 4.19603 10.4878 3.99989M10.4878 5.33345C10.7503 5.78716 11.1984 5.98677 11.6427 5.97783M11.6427 5.97783C12.1013 5.96861 12.5558 5.7371 12.7976 5.33347M12.7976 5.33347C12.9096 5.14648 12.976 4.92255 12.976 4.66667C12.976 4.42376 12.9111 4.19603 12.7976 3.99989M7.99996 10.6667V13.3333M5.33329 13.3333H10.6666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MetricIcon;
