export const mockData = [
  {
    id: 2052,
    city: {
      id: 2701,
      name: 'North',
    },
    state: 'Vermont',
    name: 'N North',
    cityName: 'North',
  },
];

export const NEIGH_TABLE_FILTER_QUERY = {
  name: 'name.contains',
  state: 'stateId.contains',
  cityName: 'cityName.contains',
  createdBy: 'createdBy.contains',
  createdDateStart: 'createdDate.greaterThanOrEqual',
  createdDateEnd: 'createdDate.lessThanOrEqual',
};
