import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect } from 'react';
import { MapContainer, MapContainerProps, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { Card, CardBody } from 'reactstrap';
const markerIcon = require('leaflet/dist/images/marker-icon.png');
const markerShadow = require('leaflet/dist/images/marker-shadow.png');

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface CustomMapContainerProps extends MapContainerProps {
  center: [number, number];
  zoom: number;
  style: React.CSSProperties;
}

export const Maps = ({
  position,
  locationData,
  center,
  locationError,
}: {
  position: [number, number] | null;
  locationData: string | null;
  center: [number, number];
  locationError: string | null;
}) => {
  const zoom: number = 25;

  return (
    <div className="h-100 w-100" style={{ position: 'relative' }}>
      <div className="map-container h-100" style={{ opacity: !locationData ? 0.3 : 1 }}>
        <MapContainer {...({ center, zoom, style: { height: '100%', width: '100%' } } as CustomMapContainerProps)}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <SetViewOnChange center={center} />
          {position && (
            <Marker position={position}>
              <Popup>{locationData}</Popup>
            </Marker>
          )}
        </MapContainer>
      </div>
      {!locationData && !!locationError && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 12000,
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.3)',
          }}
        >
          <Card className="shadow-sm" style={{ width: '350px', height: '150px' }}>
            <CardBody style={{ alignContent: 'center' }}>
              <div className="d-flex flex-column gap-3 align-items-center">
                <FontAwesomeIcon icon={faExclamationCircle} size="2x" color="red" />
                <span style={{ fontSize: '16px', fontWeight: '550' }}>{locationError}</span>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};
const SetViewOnChange = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center);
    }

    const timer = setTimeout(() => {
      map.invalidateSize();
    }, 100);

    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });

    resizeObserver.observe(map.getContainer());

    return () => {
      clearTimeout(timer);
      resizeObserver.disconnect();
    };
  }, [center, map]);

  return null;
};

export default Maps;
