import React from 'react';

function UsersIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="User Group">
        <g id="Vector">
          <path
            d="M2 12.6667V12C2 10.5273 3.19391 9.33334 4.66667 9.33334H7.33333C8.80609 9.33334 10 10.5273 10 12V12.6667M10 7.33334C11.1046 7.33334 12 6.43791 12 5.33334C12 4.22877 11.1046 3.33334 10 3.33334M14 12.6667V12C14 10.5273 12.8061 9.33334 11.3333 9.33334H11M8 5.33334C8 6.43791 7.10457 7.33334 6 7.33334C4.89543 7.33334 4 6.43791 4 5.33334C4 4.22877 4.89543 3.33334 6 3.33334C7.10457 3.33334 8 4.22877 8 5.33334Z"
            stroke="#FFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default UsersIcon;
