import React from 'react';

function UploadIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g id="Upload 1">
        <path
          id="Vector"
          d="M15.8337 12.5V14.1667C15.8337 15.0872 15.0875 15.8334 14.167 15.8334H5.83366C4.91318 15.8334 4.16699 15.0872 4.16699 14.1667V12.5M10.0003 12.5L10.0003 4.16669M10.0003 4.16669L11.667 5.83335M10.0003 4.16669L8.33366 5.83335"
          stroke="#4A5568"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default UploadIcon;
