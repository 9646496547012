import React from 'react';

function OrganizationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="Vertical Distribute">
        <g id="Vector">
          <path
            d="M2.66602 3.33334L13.3327 3.33334M13.3327 12.6667H2.66602M5.33268 9.33334H10.666C11.0342 9.33334 11.3327 9.03487 11.3327 8.66668V7.33334C11.3327 6.96515 11.0342 6.66668 10.666 6.66668H5.33268C4.96449 6.66668 4.66602 6.96515 4.66602 7.33334V8.66668C4.66602 9.03487 4.96449 9.33334 5.33268 9.33334Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default OrganizationIcon;
