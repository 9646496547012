import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { activateAction, reset } from './activate.reducer';
import { ConfirmationBox } from 'app/modules/common/confirmation-box';
import CheckCircle from 'app/shared/icons/check-circle';
import WarningIcon from 'app/shared/icons/warning';

const successAlert = (
  <Alert color="success">
    <Translate contentKey="activate.messages.success">
      <strong>Your user account has been activated.</strong> Please
    </Translate>
    <Link to="/login" className="alert-link">
      <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
    </Link>
    .
  </Alert>
);

const failureAlert = (
  <Alert color="danger">
    <Translate contentKey="activate.messages.error">
      <strong>Your user could not be activated.</strong> Please use the registration form to sign up.
    </Translate>
  </Alert>
);

export const ActivatePage = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const key = searchParams.get('key');

    dispatch(activateAction(key));
    return () => {
      dispatch(reset());
    };
  }, []);

  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div>
      <Row className="justify-content-center">
        {activationSuccess && (
          <ConfirmationBox
            message={'Your user account has been activated.'}
            buttonLink={'/login'}
            buttonText={'Login'}
            icon={<CheckCircle />}
          ></ConfirmationBox>
        )}
        {activationFailure && (
          <ConfirmationBox
            icon={<WarningIcon fill={'08625f'} stroke="#ffff" />}
            message={'Your user could not be activated, please use the registration form to sign up.'}
            buttonLink={'/account/register'}
            buttonText={'Sign up'}
          ></ConfirmationBox>
        )}
      </Row>
    </div>
  );
};

export default ActivatePage;
