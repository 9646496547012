import { IDevice } from 'app/shared/model/device.model';
import { IApplicationUser } from 'app/shared/model/application-user.model';
import { INeighborhood } from 'app/shared/model/neighborhood.model';

export interface ILocation {
  id?: number;
  address?: string | null;
  zipcode?: string | null;
  lat?: string | null;
  longitude?: string | null;
  devices?: IDevice[] | null;
  user?: IApplicationUser | null;
  neighborhood?: INeighborhood | null;
}

export const defaultValue: Readonly<ILocation> = {};
