import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Row, Col, Form, FormGroup, Input, Label, InputGroup, InputGroupText, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';
import { type FieldError, useForm } from 'react-hook-form';
import { BrandIconLarge } from 'app/shared/layout/header/header-components';
import './login.scss';
import { PasswordInput } from './password-field';
import { registerRs } from 'app/shared/util/form-utils';
import { useAppSelector } from 'app/config/store';

export interface ILoginModalProps {
  loginError: boolean;
  handleLogin: (email: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

const LoginForm = (props: ILoginModalProps) => {
  const login = ({ email, password, rememberMe }) => {
    props.handleLogin(email, password, rememberMe);
  };
  const errorObj = useAppSelector(state => state.authentication?.error);
  const [authError, setAuthError] = useState(false);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError } = props;

  useEffect(() => {
    if (loginError) {
      setError('email', { type: 'manual', message: '' });
      setError('password', { type: 'manual', message: '' });
      setAuthError(true);
    }
  }, [loginError]);

  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };

  return (
    <Form onSubmit={handleLoginSubmit} id="login-form" novalidate="novalidate">
      <Row>
        <div id="login-title" className="login-logo" data-cy="loginTitle">
          <BrandIconLarge imgH={'155px'} />
        </div>
      </Row>
      <div className="mt-10">&nbsp;</div>
      <Row>
        <Col md="12">
          <ValidatedField
            name="email"
            label={'Email*'}
            required
            placeholder="Enter email"
            autoFocus
            data-cy="email"
            className="username-input"
            validate={{ required: 'Email is required' }}
            register={register}
            error={errors.email as FieldError}
            isTouched={touchedFields.username}
            onChange={() => {
              setAuthError(false);
            }}
          />
          <PasswordInput
            errors={errors}
            touchedFields={touchedFields}
            register={register}
            placeholder="Enter Password"
            dataCy="password"
            label={translate('login.form.password')}
            onChange={() => {
              setAuthError(false);
            }}
            validate={{ required: 'Password is required' }}
            name={'password'}
          />
        </Col>

        <Col md="12">
          {authError ? (
            <div className="login-error">
              {errorObj?.status === 401 ? (
                <Translate contentKey="login.messages.error.authentication"></Translate>
              ) : (
                errorObj?.detail ?? 'Unable to login'
              )}
            </div>
          ) : null}
        </Col>
        <Col md="6">
          <FormGroup switch>
            <Input name="rememberMe" type="switch" role="switch" {...registerRs('rememberMe', {}, register)} />{' '}
            <Label check> {translate('login.form.rememberme')}</Label>
          </FormGroup>
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Link to="/account/reset/request" data-cy="forgetYourPasswordSelector">
            <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
          </Link>
        </Col>
        <Col md="12">
          <div className={'w-100 pl-40 pr-40 sign-in'}>
            <Button color="primary" className="w-100" type="submit" data-cy="submit">
              <Translate contentKey="login.form.button">Sign in</Translate>
            </Button>
          </div>
        </Col>
        <Col md="12">
          <div className={'w-100 pl-40 pr-40 register-account'}>
            <Link to="/account/register" className="w-100 btn btn-info" data-cy="registerAccount">
              <Translate contentKey="login.form.register">Sign Up</Translate>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
