import dayjs from 'dayjs';

import {
  APP_DATE_FORMAT,
  APP_DATE_FORMAT_DATE_PICKER,
  APP_DATE_FORMAT_DEFAULT,
  APP_DATE_FORMAT_MAP,
  APP_LOCAL_DATETIME_FORMAT,
  APP_TIMESTAMP_FORMAT,
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (date?: string): dayjs.Dayjs | null => (date ? dayjs(date) : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);
export const formatDate = date => (date ? dayjs(date).format(APP_DATE_FORMAT_DEFAULT) : null);
export const formatDateWithTime = date => (date ? dayjs(date).format(APP_TIMESTAMP_FORMAT) : null);
export const datePickerFormat = date => (date ? dayjs(date).format(APP_DATE_FORMAT_DATE_PICKER) : null);
export const mapDateFormat = date => (date ? dayjs(date).format(APP_DATE_FORMAT_MAP) : null);
