import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Neighborhood from './neighborhood';
import NeighborhoodDetail from './neighborhood-detail';
import NeighborhoodUpdate from './neighborhood-update';
import NeighborhoodDeleteDialog from './neighborhood-delete-dialog';

const NeighborhoodRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Neighborhood />} />
    <Route path="new" element={<NeighborhoodUpdate />} />
    <Route path=":id">
      <Route index element={<NeighborhoodDetail />} />
      <Route path="edit" element={<NeighborhoodUpdate />} />
      <Route path="delete" element={<NeighborhoodDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NeighborhoodRoutes;
